import React from 'react';
import { createPortal } from 'react-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  hideBottomNotification,
  resetBottomNotification,
} from '../../../../store/bottomNotification/bottomNotification.slice';
import { useTimeout } from '../../../../hooks';

export const BottomNotification: React.FC = () => {
  const dispatch = useAppDispatch();

  const isOpened = useAppSelector((state) => state.bottomNotification.isOpened);
  const text = useAppSelector((state) => state.bottomNotification.text);
  const position = useAppSelector((state) => state.bottomNotification.position);
  const type = useAppSelector((state) => state.bottomNotification.type);

  const attrs = {
    container: {
      className: `bottom-notification${
        type ? ` bottom-notification--${type}` : ''
      }${isOpened ? ' bottom-notification--visible' : ''}`,
      onTransitionEnd: () => {
        if (!isOpened && text) {
          dispatch(resetBottomNotification());
        }
      },
      ...(typeof position === 'number' ? { style: { bottom: position } } : {}),
    },
  };

  useTimeout(
    () => {
      dispatch(hideBottomNotification());
    },
    3000,
    isOpened
  );

  return createPortal(<div {...attrs.container}>{text}</div>, document.body);
};
