import React from 'react';

export const ChevronRightIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7844 8.87797C11.2242 8.37527 11.2242 7.62466 10.7844 7.12196L6.50182 2.22765C6.25936 1.95056 5.83819 1.92249 5.5611 2.16494C5.28401 2.4074 5.25593 2.82857 5.49839 3.10566L9.78092 7.99996L5.49838 12.8943C5.25593 13.1714 5.28401 13.5926 5.5611 13.835C5.83819 14.0775 6.25937 14.0494 6.50182 13.7723L10.7844 8.87797Z"
      fill="#070B12"
    />
  </svg>
);
