import React from 'react';

export const DefaultAccountIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 20.8833V8.73736C3 7.60104 3.64201 6.56225 4.65836 6.05408L12.5528 2.10686C13.2177 1.77441 14 2.25791 14 3.00129V6.38326V11.3833V21.8833H4C3.44771 21.8833 3 21.4355 3 20.8833ZM20 21.8833H15V14.3833V6.88326L20.0077 9.80444C20.6222 10.1629 21 10.8207 21 11.532V20.8833C21 21.4355 20.5523 21.8833 20 21.8833ZM7 7.88327C6.44772 7.88327 6 8.33099 6 8.88327C6 9.43556 6.44772 9.88327 7 9.88327H10C10.5523 9.88327 11 9.43556 11 8.88327C11 8.33099 10.5523 7.88327 10 7.88327H7ZM6 12.8833C6 12.331 6.44772 11.8833 7 11.8833H10C10.5523 11.8833 11 12.331 11 12.8833C11 13.4356 10.5523 13.8833 10 13.8833H7C6.44772 13.8833 6 13.4356 6 12.8833ZM7 15.8833C6.44772 15.8833 6 16.331 6 16.8833C6 17.4356 6.44772 17.8833 7 17.8833H10C10.5523 17.8833 11 17.4356 11 16.8833C11 16.331 10.5523 15.8833 10 15.8833H7Z"
        fill="#95A1B6"
      />
    </svg>
  );
};
