import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  EventFrameHeaderTitle,
  EventHeaderTitleProps,
} from './components/EventHeaderTitle/EventFrameHeaderTitle';
import { Account } from '../../../../types/account.types';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  openAssignAccountModal,
  openManageParticipantsModal,
} from '../../../../store/relationshipFlows/relationshipFlows.slice';
import {
  Avatar,
  AvatarProps,
  AvatarSize,
  Button,
  ButtonSize,
  ButtonVariant,
  Tooltip,
  TooltipPosition,
} from '../../../SoundWave';
import { getEventNavigationUrls } from '../../../EventPagination/utils/getEventNavigationUrls';
import { PATHS } from '../../../../constants/paths';
import { ChevronLeftIcon } from '../../../EventPagination/icons/ChevronLeftIcon';
import { ChevronRightIcon } from '../../../EventPagination/icons/ChevronRightIcon';
import { ClockIcon } from './icons/ClockIcon';
import { CalendarIcon } from './icons/CalendarIcon';
import {
  meetingDateRangeStringSelector,
  meetingHostWithContactInfoSelector,
  meetingParticipantsBySideSelector,
  meetingParticipantsWithContactInfoSelector,
  meetingRecordingDurationStringSelector,
  meetingShortDateStringSelector,
} from '../../../../store/meetingDetails/meetingDetails.selectors';
import { ThreeDotIcon } from './icons/ThreeDotIcon';
import { getTotalDurationString } from '../../../../pages/ThreadDetails/components/ThreadOverview/ThreadOverview.utils';
import ManageParticipantsModal from './components/ManageParticipantsModal/ManageParticipantsModal';
import { ThreeDotAttentionIcon } from './icons/ThreeDotAttentionIcon';
import { DefaultAccountIcon } from './icons/DefaultAccountIcon';
import { TooltipTextAlign } from '../../../SoundWave/components/Tooltip/Tooltip';
import { InfoIcon } from './icons/InfoIcon';
import { TooltipIcon } from './icons';
import { ParticipantTooltip } from '../../../../pages/MeetingDetails/components/ParticipantTooltip';
import { Contact } from '../../../../types/contact.types';
import { EventFrameHeaderSkeleton } from './components/EventFrameHeaderSkeleton/EventFrameHeaderSkeleton';
import { MeetingParticipantWithContactInfo } from '../../../../types/meetingDetails.types';

export enum DescriptionAlignment {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface EventFrameHeaderProps {
  titleProps: EventHeaderTitleProps;
  isLoading: boolean;
  rightIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  account?: Account | null;
  eventId?: string;
  isEmailAnalysis?: boolean;
}

export const EventFrameHeader: React.FC<EventFrameHeaderProps> = ({
  titleProps,
  isLoading,
  rightIcon,
  account,
  eventId,
  isEmailAnalysis = false,
}) => {
  const user = useAppSelector((state) => state.auth.user);
  const meetingDetails = useAppSelector((state) => state.meetingDetails.data);
  const threadDetails = useAppSelector((state) => state.threadDetails);

  const meetingDetailsMatch = useRouteMatch(PATHS.MEETING_DETAILS);
  const meetingRecordMatch = useRouteMatch(PATHS.MEETING_RECORD);
  const threadDetailsMatch = useRouteMatch(PATHS.THREAD_DETAILS);
  const touchPointDetailsMatch = useRouteMatch(PATHS.TOUCH_POINT_DETAILS);

  const meetingDate = useAppSelector(meetingShortDateStringSelector);
  const meetingDateRange = useAppSelector(meetingDateRangeStringSelector);
  const meetingRecordingDurationString = useAppSelector(
    meetingRecordingDurationStringSelector
  );
  const hostWithContactInfo = useAppSelector(
    meetingHostWithContactInfoSelector
  );
  const participantsWithContactInfo = useAppSelector(
    meetingParticipantsWithContactInfoSelector
  );

  const meetingParticipantsBySide = useAppSelector(
    meetingParticipantsBySideSelector
  );

  const emailParticipants = useAppSelector(
    (state) => state.threadDetails.contacts
  );

  const history = useHistory();

  const dispatch = useAppDispatch();

  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const { prevEventUrl, nextEventUrl } = getEventNavigationUrls({
    user,
    meetingDetails,
    threadDetails,
    isMeetingDetailsPage: !!meetingDetailsMatch || !!meetingRecordMatch,
    isThreadDetailsPage: !!threadDetailsMatch || !!touchPointDetailsMatch,
  });

  const RightIconComponent = rightIcon || null;

  const mappedMeetingParticipants = useMemo(
    () =>
      participantsWithContactInfo.map((participant) => ({
        participant,
        avatarProps: {
          src: participant.contact?.metadata?.avatarSrc || '',
          name: participant.contact?.metadata?.name || participant.alias || '',
          side: participant.side,
          id: participant?.participantId,
        },
      })),
    [participantsWithContactInfo]
  );

  const mappedEmailParticipants = useMemo(
    () =>
      emailParticipants.map((participant) => ({
        participant,
        avatarProps: {
          src: participant?.metadata?.avatarSrc || '',
          name: participant?.metadata?.name || '',
          side: participant?.extra?.currentAccountLink?.contactSide,
          id: participant?.contactId,
        },
      })),
    [emailParticipants]
  );

  const threadStartedBy = useMemo(
    () =>
      emailParticipants.find(
        (participant) =>
          participant.email ===
          threadDetails?.selectedBranch?.emails?.[0]?.sender?.email
      ) || null,
    [emailParticipants, threadDetails?.selectedBranch?.emails]
  );

  const hostParticipant = isEmailAnalysis
    ? threadStartedBy
    : hostWithContactInfo;

  const threadStartedAt =
    threadDetails?.selectedBranch?.emails?.[0]?.sentAt &&
    DateTime.fromISO(
      threadDetails?.selectedBranch?.emails?.[0]?.sentAt
    ).toFormat('MMM dd, yyyy');

  const lastThreadEmailAt =
    threadDetails?.selectedBranch?.emails?.[
      threadDetails?.selectedBranch?.emails?.length - 1
    ]?.sentAt &&
    DateTime.fromISO(
      threadDetails?.selectedBranch?.emails?.[
        threadDetails?.selectedBranch?.emails?.length - 1
      ]?.sentAt
    ).toFormat('MMM dd, yyyy');

  const threadLifeTime =
    lastThreadEmailAt && threadStartedAt
      ? `${threadStartedAt} - ${lastThreadEmailAt}`
      : 'N/A';

  const durationInWeeks = threadDetails?.selectedBranch?.branchDurationWeeks
    ? getTotalDurationString(
        typeof threadDetails?.selectedBranch?.branchDurationWeeks === 'number'
          ? threadDetails?.selectedBranch?.branchDurationWeeks
          : null
      )
    : 'N/A';

  const meetingParticipantContacts = useMemo(
    () =>
      participantsWithContactInfo.reduce<Contact[]>((acc, participant) => {
        if (participant.contact) {
          const contact = {
            ...participant.contact,
            metadata: {
              ...participant.contact.metadata,
              name:
                participant.contact.metadata?.name || participant.alias || '',
            },
            extra: {
              ...participant.contact.extra,
              ...(participant.contact.extra?.currentAccountLink
                ? {
                    currentAccountLink: {
                      ...participant.contact.extra?.currentAccountLink,
                      contactSide: participant.side,
                    },
                  }
                : {}),
            },
          };

          acc.push(contact);
        }

        return acc;
      }, []),
    [participantsWithContactInfo]
  );

  const attrs = {
    container: {
      className: `event-frame-header${
        showDisclaimer ? ' event-frame-header--with-disclaimer' : ''
      }`,
    },
    navigation: {
      previousButton: {
        tooltip: {
          text: 'Go to previous event in this Relationship',
          width: 155,
          position: TooltipPosition.BOTTOM_START,
          simplified: true,
          textAlign: 'center' as TooltipTextAlign,
        },
        button: {
          size: ButtonSize.XS,
          variant: ButtonVariant.Secondary,
          className: 'event-pagination__button',
          disabled: isLoading || !prevEventUrl,
          onClick: () => {
            if (prevEventUrl) {
              history.push(prevEventUrl);
            }
          },
        },
      },
      nextButton: {
        tooltip: {
          text: 'Go to next event in this Relationship',
          width: 155,
          position: TooltipPosition.BOTTOM_END,
          simplified: true,
          textAlign: 'center' as TooltipTextAlign,
        },
        button: {
          size: ButtonSize.XS,
          variant: ButtonVariant.Secondary,
          className: 'event-pagination__button',
          disabled: isLoading || !nextEventUrl,
          onClick: () => {
            if (nextEventUrl) {
              history.push(nextEventUrl);
            }
          },
        },
      },
    },
    left: {
      wrapper: {
        className: 'event-frame-header__left',
      },
      accountLogo: {
        size: AvatarSize.L,
        src: account?.avatarSrc || '',
        name: account?.name || '',
        placeholder: DefaultAccountIcon,
        hasInnerBorder: false,
        shape: 'square' as const,
        className: `${
          !account?.avatarSrc ? 'avatar-src-availbale ' : ''
        }event-frame-header__account-logo`,
      },
      eventDetails: {
        wrapper: {
          className: 'event-frame-header__left__event-details',
        },

        top: {
          wrapper: {
            className: 'event-frame-header__left__event-details__top',
          },
          account: {
            className: 'event-frame-header__left__event-details__top-account',
          },
          titleSkeleton: {
            active: true,
            paragraph: false,
            loading: isLoading,
            style: {
              width: 'auto',
            },
            title: {
              style: {
                width: 200,
                height: 36,
                margin: 0,
                borderRadius: 4,
              },
            },
          },
          separator: {
            className: 'event-frame-header__left__event-details__top-separator',
          },
          eventName: {
            className:
              'event-frame-header__left__event-details__top-event-name',
          },
        },
        bottom: {
          wrapper: {
            className: 'event-frame-header__left__event-details__bottom',
          },
          date: {
            className: 'event-frame-header__left__event-details__bottom-date',
          },
          time: {
            className: 'event-frame-header__left__event-details__bottom-time',
          },
          duration: {
            className:
              'event-frame-header__left__event-details__bottom-duration',
          },
          value: {
            className: 'event-frame-header__left__event-details__bottom-value',
          },
          separator: {
            className:
              'event-frame-header__left__event-details__bottom-separator',
          },
        },
      },
    },
    right: {
      wrapper: {
        className: 'event-frame-header__right',
      },
      content: {
        wrapper: {
          className: 'event-frame-header__right__content',
        },

        peopleInfoWrapper: {
          className: 'event-frame-header__right__content__people',
        },

        host: {
          wrapper: {
            className: 'event-frame-header__right__content__host',
          },
          avatar: {
            src:
              hostParticipant && 'contact' in hostParticipant
                ? hostParticipant?.contact?.metadata?.avatarSrc || ''
                : hostParticipant?.metadata?.avatarSrc || '',
            name:
              hostParticipant && 'contact' in hostParticipant
                ? hostParticipant.contact?.metadata?.name ||
                  hostParticipant?.alias ||
                  ''
                : hostParticipant?.metadata?.name || '',
            side:
              hostParticipant && 'side' in hostParticipant
                ? hostParticipant.side
                : hostParticipant?.extra?.currentAccountLink?.contactSide,
          },
          name: {
            className: 'event-frame-header__right__content__host-name',
          },
          text: {
            className: 'event-frame-header__right__content__host-text',
          },
        },

        praticipants: {
          wrapper: {
            className: 'event-frame-header__right__content__participants',
          },
          avatars: {
            wrapper: {
              className:
                'event-frame-header__right__content__participants__avatars-group',
            },
          },
          avatarsGroupCounter: {
            className:
              'event-frame-header__right__content__participants__avatars-group-counter',
            onClick: () => {
              const currentAccount = isEmailAnalysis
                ? threadDetails?.account
                : meetingDetails?.account || null;
              const participants = isEmailAnalysis
                ? emailParticipants
                : meetingParticipantContacts;

              dispatch(
                openManageParticipantsModal({
                  participants,
                  account: currentAccount,
                  hostContactId: isEmailAnalysis
                    ? null
                    : hostWithContactInfo?.contactId || null,
                })
              );
            },
          },
          avatarsGroupCounterTooltip: {
            className:
              'event-frame-header__right__content__participants__avatars-group-counter__tooltip',
            contentClassName:
              'event-frame-header__right__content__participants__avatars-group-counter__tooltip-content',
            text: 'View all participants',
            simplified: true,
            width: 142,
          },
          avatarsGroupCounterText: {
            className:
              'event-frame-header__right__content__participants__avatars-group-counter-text',
          },
        },

        threeDotMenu: {
          wrapper: {
            className: `${
              !participantsWithContactInfo?.length && !emailParticipants.length
                ? 'event-frame-header__right__content__three-dot-menu--disabled '
                : ''
            }event-frame-header__right__content__three-dot-menu`,
            onClick: () => {
              const currentAccount = isEmailAnalysis
                ? threadDetails?.account
                : meetingDetails?.account || null;
              const participants = isEmailAnalysis
                ? emailParticipants
                : meetingParticipantContacts;

              dispatch(
                openManageParticipantsModal({
                  participants,
                  account: currentAccount,
                  hostContactId: isEmailAnalysis
                    ? null
                    : hostWithContactInfo?.contactId || null,
                })
              );
            },
          },
          tooltip: {
            text: 'Manage Participants ',
            width: 144,
            position: TooltipPosition.TOP,
            simplified: true,
            style:
              !participantsWithContactInfo?.length && !emailParticipants.length
                ? { pointerEvents: 'none' as const }
                : {},
          },
          attentionIcon: {
            className:
              'event-frame-header__right__content__three-dot-menu-icon',
          },
          button: {
            wrapper: {
              className:
                'event-frame-header__right__content__three-dot-menu__button',
            },
            icon: {
              className:
                'event-frame-header__right__content__three-dot-menu__button-icon',
            },
          },
        },
      },
    },
    rightIcon: {
      className: 'event-frame-header__right-icon',
    },
    tooltip: {
      positionOffset: 14,
      text: 'Select Account',
      className: 'event-frame-header__account-button-tooltip',
      contentClassName: 'event-frame-header__account-button-tooltip-content',
    },
    accountButtonWrapper: {
      className: 'event-frame-header__account-button-wrapper',
    },
    accountButton: {
      disabled: isLoading || !!account,
      type: 'button' as const,
      className: 'event-frame-header__account-button',
      onClick: () => {
        if (!account && eventId) {
          setShowDisclaimer(false);
          dispatch(openAssignAccountModal({ eventId }));
        }
      },
    },
    disclaimer: {
      className: 'event-frame-header__disclaimer',
    },
    disclaimerContent: {
      className: 'event-frame-header__disclaimer-content',
    },
    disclaimerIcon: {
      className: 'event-frame-header__disclaimer-icon',
    },
    disclaimerTitle: {
      className: 'event-frame-header__disclaimer-title',
    },
    disclaimerAssociateButton: {
      type: 'button' as const,
      className: 'event-frame-header__disclaimer-associate-button',
      onClick: () => {
        if (!account && eventId) {
          setShowDisclaimer(false);
          dispatch(openAssignAccountModal({ eventId }));
        }
      },
    },
    disclaimerCloseButton: {
      type: 'button' as const,
      className: 'confirmation-modal__button',
      onClick: () => {
        setShowDisclaimer(false);
      },
    },
    disclaimerOverlay: {
      className: 'event-frame-header__disclaimer-overlay',
      onClick: () => {
        setShowDisclaimer(false);
      },
    },
  };

  const renderAvatars = (
    avatars: Array<{
      avatarProps: AvatarProps;
      participant: MeetingParticipantWithContactInfo | Contact;
    }>
  ) => {
    if (!avatars.length) {
      return null;
    }

    const avatarsToRender = avatars.length > 3 ? avatars.slice(0, 3) : avatars;
    const diff = avatars.length - avatarsToRender.length;

    return (
      <>
        {avatarsToRender.map(({ avatarProps, participant }, index) => {
          if (participant) {
            return (
              <ParticipantTooltip participant={participant} key={index}>
                <Avatar {...avatarProps} key={index} />
              </ParticipantTooltip>
            );
          }
          return <Avatar {...avatarProps} key={index} />;
        })}
        {diff ? (
          <Tooltip
            {...attrs.right.content.praticipants.avatarsGroupCounterTooltip}
          >
            <span {...attrs.right.content.praticipants.avatarsGroupCounter}>
              +{diff}{' '}
              <span
                {...attrs.right.content.praticipants.avatarsGroupCounterText}
              >
                participant{diff > 1 ? 's' : ''}
              </span>
            </span>
          </Tooltip>
        ) : null}
      </>
    );
  };

  const renderAccountName = () => {
    if (!account) {
      return (
        <div {...attrs.accountButtonWrapper}>
          <button {...attrs.accountButton}>
            <span>N/A</span>
            <Tooltip {...attrs.tooltip}>
              <TooltipIcon />
            </Tooltip>
          </button>
          <div {...attrs.disclaimer}>
            <div {...attrs.disclaimerContent}>
              <InfoIcon {...attrs.disclaimerIcon} />
              <div {...attrs.disclaimerTitle}>
                This event is not associated to an Account
              </div>
              <button {...attrs.disclaimerAssociateButton}>
                Associate Now
              </button>
              <button {...attrs.disclaimerCloseButton}>Close</button>
            </div>
          </div>
          <div {...attrs.disclaimerOverlay} />
        </div>
      );
    }

    return <span>{account.name}</span>;
  };

  useEffect(() => {
    if (!isLoading && !account) {
      setShowDisclaimer(true);
    }
  }, [account, isLoading]);

  return (
    <EventFrameHeaderSkeleton isLoading={isLoading}>
      <div {...attrs.container}>
        <div {...attrs.left.wrapper}>
          {!prevEventUrl || isLoading ? (
            <Button {...attrs.navigation.previousButton.button}>
              <ChevronLeftIcon />
            </Button>
          ) : (
            <Tooltip {...attrs.navigation.previousButton.tooltip}>
              <Button {...attrs.navigation.previousButton.button}>
                <ChevronLeftIcon />
              </Button>
            </Tooltip>
          )}
          <Avatar {...attrs.left.accountLogo} />
          <div {...attrs.left.eventDetails.wrapper}>
            <div {...attrs.left.eventDetails.top.wrapper}>
              {renderAccountName()}
              <div {...attrs.left.eventDetails.top.separator}>|</div>
              <EventFrameHeaderTitle {...titleProps} />
            </div>
            <div {...attrs.left.eventDetails.bottom.wrapper}>
              <div {...attrs.left.eventDetails.bottom.date}>
                <CalendarIcon />
                <div {...attrs.left.eventDetails.bottom.value}>
                  {isEmailAnalysis ? threadLifeTime : meetingDate}
                </div>
              </div>
              <div {...attrs.left.eventDetails.bottom.separator}>|</div>
              <div {...attrs.left.eventDetails.bottom.time}>
                <ClockIcon />
                <div {...attrs.left.eventDetails.bottom.value}>
                  {isEmailAnalysis ? durationInWeeks : meetingDateRange}
                </div>
              </div>
              <div {...attrs.left.eventDetails.bottom.separator}>|</div>
              <div {...attrs.left.eventDetails.bottom.duration}>
                {RightIconComponent ? (
                  <RightIconComponent {...attrs.rightIcon} />
                ) : null}
                <div {...attrs.left.eventDetails.bottom.value}>
                  {meetingRecordingDurationString}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div {...attrs.right.wrapper}>
          <div {...attrs.right.content.wrapper}>
            <div {...attrs.right.content.peopleInfoWrapper}>
              {hostParticipant ? (
                <div {...attrs.right.content.host.wrapper}>
                  <ParticipantTooltip participant={hostParticipant}>
                    <Avatar {...attrs.right.content.host.avatar} />
                  </ParticipantTooltip>
                  {isEmailAnalysis ? (
                    <>
                      <div {...attrs.right.content.host.text}>started by</div>
                      <div {...attrs.right.content.host.name}>
                        {'metadata' in hostParticipant
                          ? hostParticipant.metadata?.name
                          : hostParticipant.contact?.metadata?.name}
                      </div>
                    </>
                  ) : (
                    <>
                      <div {...attrs.right.content.host.name}>
                        {'contact' in hostParticipant
                          ? hostParticipant.contact?.metadata?.name
                          : hostParticipant.metadata?.name}
                      </div>
                      <div {...attrs.right.content.host.text}>host</div>
                    </>
                  )}
                </div>
              ) : null}

              <div {...attrs.right.content.praticipants.wrapper}>
                <div {...attrs.right.content.praticipants.avatars.wrapper}>
                  {isEmailAnalysis
                    ? renderAvatars(mappedEmailParticipants)
                    : renderAvatars(mappedMeetingParticipants)}
                </div>
              </div>
            </div>
            <Tooltip {...attrs.right.content.threeDotMenu.tooltip}>
              <div {...attrs.right.content.threeDotMenu.wrapper}>
                <div {...attrs.right.content.threeDotMenu.button.wrapper}>
                  <ThreeDotIcon />
                </div>
                {meetingParticipantsBySide.unassigned?.length ? (
                  <ThreeDotAttentionIcon
                    {...attrs.right.content.threeDotMenu.attentionIcon}
                  />
                ) : null}
              </div>
            </Tooltip>
          </div>
          {isLoading || !nextEventUrl ? (
            <Button {...attrs.navigation.nextButton.button}>
              <ChevronRightIcon />
            </Button>
          ) : (
            <Tooltip {...attrs.navigation.nextButton.tooltip}>
              <Button {...attrs.navigation.nextButton.button}>
                <ChevronRightIcon />
              </Button>
            </Tooltip>
          )}
        </div>
        <ManageParticipantsModal />
      </div>
    </EventFrameHeaderSkeleton>
  );
};
