export const PATHS = {
  DEALS_REDIRECT: '/orgs/:orgId/users/:userId/deals/:threadId?',
  ENGAGEMENTS: '/orgs/:orgId/users/:userId/engagements',
  ENGAGEMENT_DETAILS:
    '/orgs/:orgId/users/:userId/engagements/:engagementId/threads/:branchId',
  RELATIONSHIPS: '/orgs/:orgId/users/:userId/relationships',
  RELATIONSHIP_DETAILS: '/orgs/:orgId/users/:userId/relationships/:accountId',
  THREAD_DETAILS:
    '/orgs/:orgId/users/:userId/relationships/:accountId/threads/:threadId',
  TOUCH_POINT_DETAILS: '/orgs/:orgId/users/:userId/touch-points/:threadId',
  EMAIL_STATS: '/orgs/:orgId/users/:userId/analytics/email-stats',
  PERFORMACE_Q: '/orgs/:orgId/users/:userId/performanceq',
  TEAM_ANALYTICS: '/orgs/:orgId/users/:userId/team-analytics/:page?',
  MEETINGS: '/orgs/:orgId/users/:userId/my-meetings',
  MEETING_DETAILS:
    '/orgs/:orgId/users/:userId/relationships/:accountId/meetings/:meetingId',
  MEETING_DETAILS_OLD:
    '/orgs/:orgId/users/:userId/relationships/:accountId/meetings-old/:meetingId',
  ADMIN_SETTINGS_APPS: '/orgs/:orgId/admin-settings/apps',
  APPS: '/orgs/:orgId/users/:userId/apps',
  ADMIN_SETTINGS_TEAMS: '/orgs/:orgId/admin-settings/teams',
  TEAMS_REDIRECT: '/orgs/:orgId/users/:userId/teams',
  ADMIN_SETTINGS_ORGANIZATION: '/orgs/:orgId/admin-settings/organization',
  ORGANIZATION_DETAILS: '/orgs/:orgId',
  PERSONAL_Q: '/orgs/:orgId/users/:userId/personalq',
  PERSONAL_Q_ANALYTICS: '/orgs/:orgId/users/:userId/analytics/personalq',
  PERSONAL_Q_COMPETENCE: '/orgs/:orgId/users/:userId/personalq_competence',
  ACCOUNT: '/orgs/:orgId/users/:userId',
  ADMIN_SETTINGS: '/orgs/:orgId/admin-settings',
  ADMIN_SETTINGS_USERS: '/orgs/:orgId/admin-settings/users',
  USERS: '/orgs/:orgId/users',
  ADMIN_SETTINGS_BILLING: '/orgs/:orgId/admin-settings/billing',
  MEETING_RECORDS: '/orgs/:orgId/users/:userId/meeting-records',
  MEETING_RECORD: '/orgs/:orgId/users/:userId/meeting-records/:meetingId',
  PROFILE_REDIRECT: '/profile',
};
