import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../store/hooks';
import { getMeetingDetails } from '../../../../../../../../store/meetingDetails/meetingDetails.thunks';
import {
  closeManageParticipantsConfirmationModal,
  closeManageParticipantsErrorModal,
} from '../../../../../../../../store/relationshipFlows/relationshipFlows.slice';
import { linkContactToAccount } from '../../../../../../../../store/relationshipFlows/relationshipFlows.thunks';
import { getThreadDetails } from '../../../../../../../../store/threadDetails/threadDetails.thunks';
import { WarningIcon } from '../../../../../../../Icons/WarningIcon';
import { ConfirmationModal } from '../../../../../../../SoundWave';

const ManageParticipantsErrorModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const linkContactToAccountData = useAppSelector(
    (state) =>
      state.relatioshipFlows.manageParticipants.linkContactToAccountData
  );

  const isLoading = useAppSelector(
    (state) => state.relatioshipFlows.isLoading.linkContactToAccount
  );

  const isModalOpened = useAppSelector(
    (state) => state.relatioshipFlows.manageParticipants.isErrorModalOpened
  );

  const requestRef = useRef<(Promise<unknown> & { abort: () => void }) | null>(
    null
  );

  const { meetingId } = useParams<{
    meetingId: string;
  }>();

  const { threadId } = useParams<{
    threadId: string;
  }>();

  const attrs = {
    modal: {
      className: 'manage-participants-error',
      icon: WarningIcon,
      isOpened: isModalOpened,
      title: 'Error Occurred',
      description:
        'An error occurred while reassigning the participant. Please try again or return to Participant Management.',
      footerProps: {
        isLoading,
        cancelButtonText: 'Back',
        confirmButtonText: 'Try Again',
        isConfirmButtonDanger: true,
        onConfirm: () => {
          if (linkContactToAccountData) {
            dispatch(linkContactToAccount(linkContactToAccountData))
              .unwrap()
              .then(() => {
                if (threadId) {
                  requestRef.current = dispatch(getThreadDetails(threadId));
                } else if (meetingId) {
                  requestRef.current = dispatch(getMeetingDetails(meetingId));
                }
              });
          }
        },
      },
      onClose: () => {
        dispatch(closeManageParticipantsErrorModal());
        dispatch(closeManageParticipantsConfirmationModal());
      },
    },
  };

  useEffect(() => {
    const currentRequest = requestRef.current;

    return () => {
      currentRequest?.abort();
    };
  }, [meetingId, threadId]);

  return <ConfirmationModal {...attrs.modal} />;
};

export default ManageParticipantsErrorModal;
