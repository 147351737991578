import React from 'react';
import { Skeleton } from 'antd';
import {
  Avatar,
  AvatarSize,
  Button,
  ButtonSize,
  ButtonVariant,
  ChevronRightIcon,
} from '../../../../../SoundWave';
import { DefaultAccountIcon } from '../../icons/DefaultAccountIcon';
import { ThreeDotIcon } from '../../icons/ThreeDotIcon';
import { ChevronLeftIcon } from '../../../../../EventPagination/icons/ChevronLeftIcon';

interface Props {
  isLoading: boolean;
  children: JSX.Element;
}

export const EventFrameHeaderSkeleton: React.FC<Props> = ({
  isLoading,
  children,
}): JSX.Element => {
  const attrs = {
    wrapper: {
      className: 'efh-skeleton',
    },
    navigation: {
      prevButton: {
        size: ButtonSize.XS,
        variant: ButtonVariant.Secondary,
        className: 'event-pagination__button',
        disabled: true,
      },
      nextButton: {
        size: ButtonSize.XS,
        variant: ButtonVariant.Secondary,
        className: 'event-pagination__button',
        disabled: true,
      },
    },
    left: {
      wrapper: {
        className: 'efh-skeleton__left',
      },
      accountLogo: {
        size: AvatarSize.L,
        src: '',
        name: '',
        placeholder: DefaultAccountIcon,
        hasInnerBorder: false,
        shape: 'square' as const,
        className: 'avatar-src-availbale event-frame-header__account-logo',
      },
      info: {
        wrapper: {
          className: 'efh-skeleton__left__info',
        },
        top: {
          wrapper: {
            className: 'efh-skeleton__left__info__top',
          },
          cell: {
            className: 'efh-skeleton__left__info__top-cell',
          },
        },
        bottom: {
          wrapper: {
            className: 'efh-skeleton__left__info__bottom',
          },
          cell: {
            className: 'efh-skeleton__left__info__bottom-cell',
          },
        },
        separator: {
          className: 'efh-skeleton__left__info-separator',
        },
      },
    },
    right: {
      wrapper: {
        className: 'efh-skeleton__right',
      },
      content: {
        wrapper: {
          className: 'efh-skeleton__right__content',
        },
        participants: {
          className: 'efh-skeleton__right__content__participants-section',
        },
        threeDotMenu: {
          wrapper: {
            className: 'efh-skeleton__right__content__three-dot-menu',
          },
          button: {
            className: 'efh-skeleton__right__content__three-dot-menu__button',
          },
          icon: {
            className:
              'efh-skeleton__right__content__three-dot-menu__button-icon',
          },
        },
      },
    },
    iconSkeleton: {
      active: true,
      paragraph: false,
      title: false,
      avatar: {
        shape: 'circle' as const,
        size: 16,
      },
      style: {
        width: 'auto',
      },
    },
    textSkeleton: {
      active: true,
      paragraph: false,
      title: {
        width: 'auto',
        style: {
          width: '100%',
          height: 16,
          borderRadius: 8,
        },
      },
    },
    avatarSkeleton: {
      active: true,
      paragraph: false,
      title: false,
      avatar: {
        shape: 'circle' as const,
        size: 40,
      },
      style: {
        width: 'auto',
      },
    },
    longTextSkeleton: {
      active: true,
      paragraph: false,
      title: {
        width: 128,
        style: {
          width: 128,
          height: 16,
          borderRadius: 8,
          margin: 0,
        },
      },
    },
  };

  const leftSection = (
    <div {...attrs.left.wrapper}>
      <Button {...attrs.navigation.nextButton}>
        <ChevronLeftIcon />
      </Button>
      <Avatar {...attrs.left.accountLogo} />
      <div {...attrs.left.info.wrapper}>
        <div {...attrs.left.info.top.wrapper}>
          <div {...attrs.left.info.top.cell}>
            <Skeleton {...attrs.textSkeleton} />
          </div>
          <div {...attrs.left.info.separator}>|</div>
          <div {...attrs.left.info.top.cell}>
            <Skeleton {...attrs.textSkeleton} />
          </div>
        </div>
        <div {...attrs.left.info.bottom.wrapper}>
          <div {...attrs.left.info.bottom.cell}>
            <Skeleton {...attrs.iconSkeleton} />
            <Skeleton {...attrs.textSkeleton} />
          </div>
          <div {...attrs.left.info.separator}>|</div>
          <div {...attrs.left.info.bottom.cell}>
            <Skeleton {...attrs.iconSkeleton} />
            <Skeleton {...attrs.textSkeleton} />
          </div>
          <div {...attrs.left.info.separator}>|</div>
          <div {...attrs.left.info.bottom.cell}>
            <Skeleton {...attrs.iconSkeleton} />
            <Skeleton {...attrs.textSkeleton} />
          </div>
        </div>
      </div>
    </div>
  );

  const rightSection = (
    <div {...attrs.right.wrapper}>
      <div {...attrs.right.content.wrapper}>
        <div {...attrs.right.content.participants}>
          <Skeleton {...attrs.avatarSkeleton} />
          <Skeleton {...attrs.longTextSkeleton} />
        </div>
        <div {...attrs.right.content.threeDotMenu.wrapper}>
          <div {...attrs.right.content.threeDotMenu.button}>
            <ThreeDotIcon />
          </div>
        </div>
      </div>
      <Button {...attrs.navigation.nextButton}>
        <ChevronRightIcon />
      </Button>
    </div>
  );

  const skeletonContent = (
    <div {...attrs.wrapper}>
      {leftSection}
      {rightSection}
    </div>
  );

  return isLoading ? skeletonContent : children;
};
