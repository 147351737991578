import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeetingDetailsState } from './meetingDetails.types';
import {
  changeParticipantSide,
  getMeetingDetails,
  updateMeetingTitle,
} from './meetingDetails.thunks';
import {
  assignEventToAccount,
  updateContact,
} from '../relationshipFlows/relationshipFlows.thunks';

export const initialState: MeetingDetailsState = {
  isLoading: {
    meetingDetails: false,
    changeParticipantSide: false,
    updateMeetingTitle: false,
  },
  isError: {
    meetingDetails: false,
    changeParticipantSide: false,
    updateMeetingTitle: false,
  },
  data: null,
  transcriptionSearch: {
    value: '',
    foundItemsTimestamps: [],
  },
  isPlayerSignalsActive: true,
  distOfTurnsChartSelectedIds: [],
};

const setSelectedId = (currentIds: string[], idToSet: string): string[] => {
  if (currentIds.includes(idToSet)) {
    return currentIds.filter((el) => el !== idToSet);
  }
  if (currentIds?.length === 2) {
    const resultArr = [currentIds[1], idToSet];
    return resultArr;
  }
  if (currentIds?.length === 1 && currentIds[0].length) {
    const resultArr = [currentIds[0], idToSet];
    return resultArr;
  }
  if (currentIds?.length === 1 && !currentIds[0].length) {
    const resultArr = [idToSet];
    return resultArr;
  }

  return [...currentIds, idToSet];
};

const meetingDetailsSlice = createSlice({
  name: 'meetingDetails',
  initialState,
  reducers: {
    clearMeetingDetails: (state) => {
      state.data = null;
      state.transcriptionSearch = initialState.transcriptionSearch;
    },
    updateTranscriptionSearchValue: (state, action: PayloadAction<string>) => {
      state.transcriptionSearch.foundItemsTimestamps =
        initialState.transcriptionSearch.foundItemsTimestamps;
      state.transcriptionSearch.value = action.payload;
    },
    updateFoundTranscriptionSearchItems: (
      state,
      action: PayloadAction<number>
    ) => {
      state.transcriptionSearch.foundItemsTimestamps = state.transcriptionSearch.foundItemsTimestamps.includes(
        action.payload
      )
        ? state.transcriptionSearch.foundItemsTimestamps
        : [...state.transcriptionSearch.foundItemsTimestamps, action.payload];
    },
    resetTranscriptionSearchItems: (state) => {
      state.transcriptionSearch.foundItemsTimestamps =
        initialState.transcriptionSearch.foundItemsTimestamps;
    },
    resetTranscritionSearch: (state) => {
      state.transcriptionSearch = initialState.transcriptionSearch;
    },
    togglePlayerSignals: (state, action: PayloadAction<boolean>) => {
      state.isPlayerSignalsActive = action.payload;
    },
    setSelectedIdDistOfTurnsChart: (state, action: PayloadAction<string>) => {
      state.distOfTurnsChartSelectedIds = setSelectedId(
        state.distOfTurnsChartSelectedIds,
        action.payload
      );
    },
    resetSelectedIdsOfDistOfTurnsChart: (state) => {
      state.distOfTurnsChartSelectedIds = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMeetingDetails.pending, (state) => {
      state.isLoading.meetingDetails = true;
      state.isError.meetingDetails = false;
    });
    builder.addCase(getMeetingDetails.fulfilled, (state, action) => {
      state.isLoading.meetingDetails = false;
      state.data = action.payload;
    });
    builder.addCase(getMeetingDetails.rejected, (state, action) => {
      state.isLoading.meetingDetails = false;
      state.isError.meetingDetails = !action.meta.aborted;
    });
    builder.addCase(changeParticipantSide.pending, (state, action) => {
      state.isLoading.changeParticipantSide = true;
      state.isError.changeParticipantSide = false;

      if (state.data) {
        state.data.host =
          state.data.host?.participantId === action.meta.arg.participantId
            ? {
                ...state.data.host,
                side: action.meta.arg.side,
              }
            : state.data.host;

        state.data.participants = state.data.participants.map((participant) => {
          if (participant.participantId === action.meta.arg.participantId) {
            return {
              ...participant,
              side: action.meta.arg.side,
            };
          }
          return participant;
        });
      }
    });
    builder.addCase(changeParticipantSide.fulfilled, (state, action) => {
      state.isLoading.changeParticipantSide = false;

      if (state.data) {
        state.data.contacts = state.data.contacts.map((contact) => {
          if (contact.contactId === action.payload.contactId) {
            return action.payload;
          }
          return contact;
        });
      }
    });
    builder.addCase(changeParticipantSide.rejected, (state, action) => {
      state.isLoading.changeParticipantSide = false;
      state.isError.changeParticipantSide = !action.meta.aborted;
    });
    builder.addCase(updateMeetingTitle.pending, (state, action) => {
      state.isLoading.updateMeetingTitle = true;
      state.isError.updateMeetingTitle = false;

      if (state.data) {
        state.data.title = action.meta.arg;
      }
    });
    builder.addCase(updateMeetingTitle.fulfilled, (state) => {
      state.isLoading.updateMeetingTitle = false;
    });
    builder.addCase(updateMeetingTitle.rejected, (state, action) => {
      state.isLoading.updateMeetingTitle = false;
      state.isError.updateMeetingTitle = !action.meta.aborted;
    });
    builder.addCase(assignEventToAccount.fulfilled, (state, action) => {
      if (state.data && action.payload.account && action.payload.touchPoint) {
        state.data.account =
          state.data.meetingId === action.payload.touchPoint.touchPointId
            ? action.payload.account
            : state.data.account;
      }
    });
    builder.addCase(updateContact.fulfilled, (state, action) => {
      if (state.data) {
        state.data.contacts = state.data.contacts.map((contact) =>
          contact.contactId === action.payload.contactId
            ? {
                ...action.payload,
                extra: {
                  ...contact.extra,
                  ...action.payload.extra,
                },
              }
            : contact
        );
      }
    });
  },
});

export const {
  clearMeetingDetails,
  updateTranscriptionSearchValue,
  updateFoundTranscriptionSearchItems,
  resetTranscriptionSearchItems,
  resetTranscritionSearch,
  togglePlayerSignals,
  setSelectedIdDistOfTurnsChart,
  resetSelectedIdsOfDistOfTurnsChart,
} = meetingDetailsSlice.actions;

export default meetingDetailsSlice.reducer;
