import React from 'react';

const ArrowIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M2.66671 3.33398C2.66671 2.96579 2.96518 2.66732 3.33337 2.66732H8.00004C8.36823 2.66732 8.66671 2.36884 8.66671 2.00065C8.66671 1.63246 8.36823 1.33398 8.00004 1.33398H3.33337C2.2288 1.33398 1.33337 2.22941 1.33337 3.33398V12.6673C1.33337 13.7719 2.2288 14.6673 3.33337 14.6673H12.6667C13.7713 14.6673 14.6667 13.7719 14.6667 12.6673V8.00065C14.6667 7.63246 14.3682 7.33398 14 7.33398C13.6319 7.33398 13.3334 7.63246 13.3334 8.00065V12.6673C13.3334 13.0355 13.0349 13.334 12.6667 13.334H3.33337C2.96518 13.334 2.66671 13.0355 2.66671 12.6673V3.33398Z"
      fill="#070B12"
    />
    <path
      d="M10 2.00065C10 1.63246 10.2985 1.33398 10.6667 1.33398H13.3334C14.0698 1.33398 14.6667 1.93094 14.6667 2.66732V5.33398C14.6667 5.70217 14.3682 6.00065 14 6.00065C13.6319 6.00065 13.3334 5.70217 13.3334 5.33398V3.61013L8.47145 8.47206C8.2111 8.73241 7.78899 8.73241 7.52864 8.47206C7.26829 8.21171 7.26829 7.7896 7.52864 7.52925L12.3906 2.66732H10.6667C10.2985 2.66732 10 2.36884 10 2.00065Z"
      fill="#070B12"
    />
  </svg>
);

export default ArrowIcon;
