import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  hostParticipantSelector,
  participantsBySideSelector,
} from '../../../../../../store/relationshipFlows/relationshipFlows.selectors';
import {
  closeManageParticipantsModal,
  resetManageParticipantsFlow,
} from '../../../../../../store/relationshipFlows/relationshipFlows.slice';
import { Contact } from '../../../../../../types/contact.types';
import { Modal } from '../../../../../SoundWave';
import {
  ManageParticipantsConfirmationModal,
  ManageParticipantsErrorModal,
  ParticipantItem,
} from './components';

const ManageParticipantsModal: React.FC = () => {
  const organization = useAppSelector((state) => state.auth.org);
  const account = useAppSelector(
    (state) => state.relatioshipFlows.manageParticipants.account
  );

  const isModalOpened = useAppSelector(
    (state) => state.relatioshipFlows.manageParticipants.isModalOpened
  );

  const { prospectSide = [], userSide = [], unassigned = [] } = useAppSelector(
    participantsBySideSelector
  );

  const hostParticipant = useAppSelector(hostParticipantSelector);

  const dispatch = useAppDispatch();

  const attrs = {
    modal: {
      isOpened: isModalOpened,
      closeOnClickOutside: true,
      width: 756,
      onClose: () => {
        dispatch(closeManageParticipantsModal());
      },
      onAnimationEnd: () => {
        dispatch(resetManageParticipantsFlow());
      },
      headerProps: {
        title: 'Participants',
      },
    },
    container: {
      className: 'manage-participants',
    },
    sidesWrapper: {
      className: `manage-participants__sides-wrapper${
        !prospectSide.length || !userSide.length
          ? ' manage-participants__sides-wrapper--full-width'
          : ''
      }`,
    },
    accountName: {
      className: 'manage-participants__account-name',
    },
    unassignedWrapper: {
      className: 'manage-participants__unassigned-wrapper',
    },
    sideMembers: {
      className: 'manage-participants__side-members',
    },
    sideMembersBordered: {
      className:
        'manage-participants__side-members manage-participants__side-members--all-bordered',
    },
  };

  const prospectSideMembersProps =
    prospectSide.length >= userSide.length
      ? attrs.sideMembers
      : attrs.sideMembersBordered;

  const userSideMembersProps =
    userSide.length >= prospectSide.length
      ? attrs.sideMembers
      : attrs.sideMembersBordered;

  const renderParticipants = (participants: Contact[]) =>
    participants.map((participant) => {
      const props = {
        participant,
        key: participant.contactId,
        isHost: hostParticipant?.contactId === participant.contactId,
        prospectAccount: account,
        userOrganization: organization,
      };

      return <ParticipantItem {...props} />;
    });

  return (
    <>
      <Modal {...attrs.modal}>
        <div {...attrs.container}>
          {prospectSide.length || userSide.length ? (
            <div {...attrs.sidesWrapper}>
              {account && prospectSide.length ? (
                <div {...prospectSideMembersProps}>
                  <div {...attrs.accountName}>{account.name}</div>
                  {renderParticipants(prospectSide)}
                </div>
              ) : null}
              {organization && userSide.length ? (
                <div {...userSideMembersProps}>
                  <div {...attrs.accountName}>{organization.name}</div>
                  {renderParticipants(userSide)}
                </div>
              ) : null}
            </div>
          ) : null}
          {unassigned.length ? (
            <div {...attrs.unassignedWrapper}>
              {renderParticipants(unassigned)}
            </div>
          ) : null}
        </div>
      </Modal>
      <ManageParticipantsConfirmationModal />
      <ManageParticipantsErrorModal />
    </>
  );
};

export default ManageParticipantsModal;
