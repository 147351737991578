import { Contact } from '../../../types/contact.types';
import { MeetingParticipantWithContactInfo } from '../../../types/meetingDetails.types';

const getParticipantName = (
  participant?: MeetingParticipantWithContactInfo | Contact | null,
  isFullName = false
): string => {
  if (!participant) {
    return 'N/A';
  }

  const contact = 'contact' in participant ? participant.contact : participant;
  const [firstNameFromString, lastNameFromString] =
    contact?.metadata?.name?.split(' ') || [];

  const firstName = contact?.metadata?.firstName || firstNameFromString || '';
  const lastName = contact?.metadata?.lastName || lastNameFromString || '';
  const alias = 'alias' in participant ? participant.alias || '' : '';

  if (firstName) {
    return isFullName
      ? `${firstName}${lastName ? ` ${lastName}` : ''}`
      : `${firstName}${lastName ? ` ${lastName[0]}.` : ''}`;
  }

  return alias || 'N/A';
};

export default getParticipantName;
