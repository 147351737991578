import React from 'react';

const OptionsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M9.16668 3.66667C9.16668 4.31099 8.64438 4.83333 8.00001 4.83333C7.35564 4.83333 6.83334 4.31099 6.83334 3.66667C6.83334 3.02234 7.35564 2.5 8.00001 2.5C8.64438 2.5 9.16668 3.02234 9.16668 3.66667Z"
      fill="#95A1B6"
    />
    <path
      d="M9.16668 8C9.16668 8.64433 8.64438 9.16667 8.00001 9.16667C7.35564 9.16667 6.83334 8.64433 6.83334 8C6.83334 7.35567 7.35564 6.83333 8.00001 6.83333C8.64438 6.83333 9.16668 7.35567 9.16668 8Z"
      fill="#95A1B6"
    />
    <path
      d="M8.00001 13.5C8.64438 13.5 9.16668 12.9777 9.16668 12.3333C9.16668 11.689 8.64438 11.1667 8.00001 11.1667C7.35564 11.1667 6.83334 11.689 6.83334 12.3333C6.83334 12.9777 7.35564 13.5 8.00001 13.5Z"
      fill="#95A1B6"
    />
  </svg>
);

export default OptionsIcon;
