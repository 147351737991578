import React, { useMemo } from 'react';
import { Skeleton } from 'antd';
import { Contact } from '../../types/contact.types';
import { CrossIcon } from '../Icons/CrossIcon';
import { RelationshipMemberSide } from '../../types/relationshipMember.types';
import { Avatar, AvatarSize, Tooltip } from '../SoundWave';
import { CrmConnectionIcon } from '../Icons/CrmConnectionIcon';
import { getRelationshipAgeString } from './utils/getRelationshipAgeString';
import { LogoPlaceholderIcon } from '../Icons';

export interface RelationshipDetailsAccount {
  name: string;
  logoSrc?: string | null;
  teamMembers?: Contact[];
  associatedContacts?: Contact[];
}

export interface RelationshipDetailsHeaderProps {
  isLoading?: boolean;
  isCrmConnected?: boolean;
  sellerAccountDetails?: RelationshipDetailsAccount;
  buyerAccountDetails?: RelationshipDetailsAccount;
  dealStage?: string;
  dealSize?: string;
  relationshipStartDate?: string;
}

export const RelationshipDetailsHeader: React.FC<RelationshipDetailsHeaderProps> = ({
  isLoading = false,
  isCrmConnected = false,
  sellerAccountDetails,
  buyerAccountDetails,
  relationshipStartDate,
  dealStage,
  dealSize,
}) => {
  const relationshipAgeString = useMemo(
    () => getRelationshipAgeString(relationshipStartDate),
    [relationshipStartDate]
  );

  const attrs = {
    container: {
      className: 'relationship-details-header',
    },
    accountsWrapper: {
      className: 'relationship-details-header__accounts-wrapper',
    },
    account: {
      className: 'relationship-details-header__account',
    },
    accountLogoWrapper: {
      className: 'relationship-details-header__account-logo-wrapper',
    },
    accountInfo: {
      className: 'relationship-details-header__account-info',
    },
    accountInfoItem: {
      className: 'relationship-details-header__account-info-item',
    },
    accountName: {
      className: 'relationship-details-header__account-name',
    },
    accountParticipants: {
      className: 'relationship-details-header__account-participants',
    },
    accountParticipantsSmall: {
      className:
        'relationship-details-header__account-participants relationship-details-header__account-participants--small',
    },
    accountText: {
      className: 'relationship-details-header__account-text',
    },
    accountTextCounter: {
      className:
        'relationship-details-header__account-text relationship-details-header__account-text--counter',
    },
    accountTextGray: {
      className:
        'relationship-details-header__account-text relationship-details-header__account-text--gray',
    },
    crossIconWrapper: {
      className: 'relationship-details-header__cross-icon-wrapper',
    },
    crossIcon: {
      className: 'relationship-details-header__cross-icon',
    },
    infoWrapper: {
      className: 'relationship-details-header__info-wrapper',
    },
    infoItem: {
      className: `relationship-details-header__info-item${
        isLoading ? ' relationship-details-header__info-item--loading' : ''
      }`,
    },
    infoItemTitle: {
      className: 'relationship-details-header__info-item-title',
    },
    unassignedContacts: {
      className: 'relationship-details-header__unassigned-contacts',
    },
    crmConnectionIcon: {
      className: `relationship-details-header__crm-connection-icon${
        isCrmConnected
          ? ' relationship-details-header__crm-connection-icon--active'
          : ''
      }`,
    },
    crmConnectionTooltip: {
      text: 'CRM Connection: Not set',
      width: 166,
      simplified: true,
    },
    accountNameSkeleton: {
      active: true,
      paragraph: false,
      style: {
        width: 80,
      },
      title: {
        style: {
          width: 80,
          height: 24,
          margin: 0,
          borderRadius: 12,
        },
      },
    },
    textSkeleton: {
      active: true,
      paragraph: false,
      style: {
        width: 141,
      },
      title: {
        style: {
          width: 141,
          height: 24,
          margin: 0,
          borderRadius: 16,
        },
      },
    },
    textSkeletonSmall: {
      active: true,
      paragraph: false,
      style: {
        width: 114,
      },
      title: {
        style: {
          width: 114,
          height: 16,
          margin: 0,
          borderRadius: 16,
        },
      },
    },
    avatarSkeleton: {
      className: 'relationship-details-header__avatar-skeleton',
    },
  };

  const renderAccountParticipants = (
    participants?: Contact[],
    side?: RelationshipMemberSide,
    avatarSize?: AvatarSize
  ) => {
    if (isLoading) {
      return (
        <>
          <div {...attrs.avatarSkeleton} />
          <div {...attrs.avatarSkeleton} />
          <div {...attrs.avatarSkeleton} />
        </>
      );
    }

    if (!participants?.length) {
      return <span {...attrs.accountTextGray}>no members yet</span>;
    }

    const participantsToRender = participants.slice(0, 3);
    const diff = participants.length - participantsToRender.length;

    return (
      <>
        {participantsToRender.map((participant, index) => {
          const avatarProps = {
            side,
            key: index,
            size: avatarSize || AvatarSize.S,
            src: participant.metadata.avatarSrc || '',
            name: participant.metadata.name || participant.email || '',
          };

          return <Avatar {...avatarProps} />;
        })}
        {diff > 0 && <span {...attrs.accountTextCounter}>+{diff}</span>}
      </>
    );
  };

  const renderAccountLogo = (
    logoSrc?: string | null,
    side?: RelationshipMemberSide
  ) => {
    const props = {
      accountLogo: {
        src: logoSrc || '',
        className: 'relationship-details-header__account-logo',
      },
      accountSide: {
        className: `relationship-details-header__account-side${
          side ? ` relationship-details-header__account-side--${side}` : ''
        }`,
      },
      accountLogoPlaceholder: {
        className: 'relationship-details-header__account-logo-placeholder',
      },
    };

    if (logoSrc) {
      return (
        <>
          <img {...props.accountLogo} alt="" />
          <div {...props.accountSide} />
        </>
      );
    }

    return (
      <>
        <LogoPlaceholderIcon {...props.accountLogoPlaceholder} />
        <div {...props.accountSide} />
      </>
    );
  };

  const renderAssociatedContacts = (
    assosiatedContacts?: Contact[],
    side?: RelationshipMemberSide
  ) => {
    if (isLoading || !assosiatedContacts?.length) {
      return null;
    }

    return (
      <div {...attrs.accountInfoItem}>
        <span {...attrs.accountText}>Associated contacts:</span>
        <div {...attrs.accountParticipants}>
          {renderAccountParticipants(assosiatedContacts, side, AvatarSize.XS)}
        </div>
      </div>
    );
  };

  const renderAccountDetails = (
    accountDetails?: RelationshipDetailsAccount,
    side?: RelationshipMemberSide
  ) => (
    <div {...attrs.account}>
      <div {...attrs.accountLogoWrapper}>
        {renderAccountLogo(accountDetails?.logoSrc, side)}
      </div>
      <div {...attrs.accountInfo}>
        <div {...attrs.accountInfoItem}>
          {isLoading ? (
            <Skeleton {...attrs.accountNameSkeleton} />
          ) : (
            <span {...attrs.accountName}>{accountDetails?.name}</span>
          )}
          <div {...attrs.accountParticipants}>
            {renderAccountParticipants(accountDetails?.teamMembers, side)}
          </div>
        </div>
        {renderAssociatedContacts(accountDetails?.associatedContacts, side)}
      </div>
    </div>
  );

  return (
    <div {...attrs.container}>
      <div {...attrs.accountsWrapper}>
        {renderAccountDetails(
          buyerAccountDetails,
          RelationshipMemberSide.PROSPECT_SIDE
        )}
        <div {...attrs.crossIconWrapper}>
          <CrossIcon {...attrs.crossIcon} />
        </div>
        {renderAccountDetails(
          sellerAccountDetails,
          RelationshipMemberSide.USER_SIDE
        )}
      </div>
      <div {...attrs.infoWrapper}>
        <div {...attrs.infoItem}>
          <div>
            {isLoading ? (
              <Skeleton {...attrs.textSkeleton} />
            ) : (
              <span {...attrs.infoItemTitle}>Relationship Age</span>
            )}
          </div>
          <div>
            {isLoading ? (
              <Skeleton {...attrs.textSkeletonSmall} />
            ) : (
              <span>{relationshipAgeString}</span>
            )}
          </div>
        </div>
        <div {...attrs.infoItem}>
          <div>
            {isLoading ? (
              <Skeleton {...attrs.textSkeleton} />
            ) : (
              <span {...attrs.infoItemTitle}>
                <span>Deal Stage</span>
                <Tooltip {...attrs.crmConnectionTooltip}>
                  <CrmConnectionIcon {...attrs.crmConnectionIcon} />
                </Tooltip>
              </span>
            )}
          </div>
          <div>
            {isLoading ? (
              <Skeleton {...attrs.textSkeletonSmall} />
            ) : (
              <span>{dealStage}</span>
            )}
          </div>
        </div>
        <div {...attrs.infoItem}>
          <div>
            {isLoading ? (
              <Skeleton {...attrs.textSkeleton} />
            ) : (
              <span {...attrs.infoItemTitle}>
                <span>Est. Deal Size</span>
                <Tooltip {...attrs.crmConnectionTooltip}>
                  <CrmConnectionIcon {...attrs.crmConnectionIcon} />
                </Tooltip>
              </span>
            )}
          </div>
          <div>
            {isLoading ? (
              <Skeleton {...attrs.textSkeletonSmall} />
            ) : (
              <span>{dealSize}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
