import React, { useCallback, useMemo, useState } from 'react';
import { getParticipantName } from '../../../../../../../../pages/MeetingDetails/utils';
import { useAppDispatch } from '../../../../../../../../store/hooks';
import {
  openAddMemberModal,
  openManageParticipantsConfirmationModal,
} from '../../../../../../../../store/relationshipFlows/relationshipFlows.slice';
import { Organization } from '../../../../../../../../types';
import { Account } from '../../../../../../../../types/account.types';
import { Contact } from '../../../../../../../../types/contact.types';
import {
  RelationshipMemberAssociationType,
  RelationshipMemberSide,
} from '../../../../../../../../types/relationshipMember.types';
import { Avatar, AvatarSize, Dropdown } from '../../../../../../../SoundWave';
import { CheckedIcon } from '../../../../../../../SoundWave/components/FormField/icons/CheckedIcon';
import { ArrowIcon, HostIcon, OptionsIcon, WarningIcon } from './icons';

export interface ParticipantItemProps {
  isHost?: boolean;
  userOrganization: Organization | null;
  participant: Contact;
  prospectAccount?: Account | null;
}

const ParticipantItem: React.FC<ParticipantItemProps> = ({
  isHost = false,
  participant,
  userOrganization,
  prospectAccount,
}) => {
  const { email } = participant;
  const associationType =
    participant.extra?.currentAccountLink?.associationType;
  const side = participant.extra?.currentAccountLink?.contactSide;
  const isUnassigned = !side || side === RelationshipMemberSide.UNASSIGNED;

  const dispatch = useAppDispatch();

  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const handleCloseDropdown = useCallback(() => {
    setIsDropdownOpened(false);
  }, []);

  const dropdownOptions = useMemo(() => {
    return [
      {
        label: 'Change Assignment',
        className: 'manage-participant-item__dropdown-option',
        isExpanded: true,
        disabled: true,
        suboptions: [
          ...(prospectAccount
            ? [
                {
                  label: prospectAccount?.name
                    ? `${prospectAccount.name} Team Member`
                    : 'Prospect Team Member',
                  disabled:
                    side === RelationshipMemberSide.PROSPECT_SIDE &&
                    associationType ===
                      RelationshipMemberAssociationType.TEAM_MEMBER,
                  ...(side === RelationshipMemberSide.PROSPECT_SIDE &&
                    associationType ===
                      RelationshipMemberAssociationType.TEAM_MEMBER && {
                      icon: <CheckedIcon />,
                    }),
                  className: 'manage-participant-item__dropdown-suboption',
                  onClick: () => {
                    setIsDropdownOpened(false);
                    dispatch(
                      openManageParticipantsConfirmationModal({
                        note: participant.extra?.currentAccountLink?.note || '',
                        contactId: participant.contactId || '',
                        accountId: prospectAccount.accountId || '',
                        contactType:
                          RelationshipMemberAssociationType.TEAM_MEMBER,
                        assignedTo: RelationshipMemberSide.PROSPECT_SIDE,
                      })
                    );
                  },
                },
                {
                  label: prospectAccount?.name
                    ? `${prospectAccount.name} 3rd Party`
                    : 'Prospect 3rd Party',
                  disabled:
                    side === RelationshipMemberSide.PROSPECT_SIDE &&
                    associationType ===
                      RelationshipMemberAssociationType.THIRD_PARTY,
                  ...(side === RelationshipMemberSide.PROSPECT_SIDE &&
                    associationType ===
                      RelationshipMemberAssociationType.THIRD_PARTY && {
                      icon: <CheckedIcon />,
                    }),
                  className: 'manage-participant-item__dropdown-suboption',
                  onClick: () => {
                    setIsDropdownOpened(false);
                    dispatch(
                      openManageParticipantsConfirmationModal({
                        note: participant.extra?.currentAccountLink?.note || '',
                        contactId: participant.contactId || '',
                        accountId: prospectAccount.accountId || '',
                        contactType:
                          RelationshipMemberAssociationType.THIRD_PARTY,
                        assignedTo: RelationshipMemberSide.PROSPECT_SIDE,
                      })
                    );
                  },
                },
              ]
            : []),
          ...(userOrganization
            ? [
                {
                  label: userOrganization?.name
                    ? `${userOrganization.name} Team Member`
                    : 'User Team Member',
                  disabled:
                    !prospectAccount ||
                    (side === RelationshipMemberSide.USER_SIDE &&
                      associationType ===
                        RelationshipMemberAssociationType.TEAM_MEMBER),
                  ...(side === RelationshipMemberSide.USER_SIDE &&
                    associationType ===
                      RelationshipMemberAssociationType.TEAM_MEMBER && {
                      icon: <CheckedIcon />,
                    }),
                  className: 'manage-participant-item__dropdown-suboption',
                  onClick: () => {
                    setIsDropdownOpened(false);
                    dispatch(
                      openManageParticipantsConfirmationModal({
                        note: participant.extra?.currentAccountLink?.note || '',
                        contactId: participant.contactId || '',
                        accountId: prospectAccount?.accountId || '',
                        contactType:
                          RelationshipMemberAssociationType.TEAM_MEMBER,
                        assignedTo: RelationshipMemberSide.USER_SIDE,
                      })
                    );
                  },
                },
                {
                  label: userOrganization?.name
                    ? `${userOrganization.name} 3rd Party`
                    : 'User 3rd Party',
                  disabled:
                    !prospectAccount ||
                    (side === RelationshipMemberSide.USER_SIDE &&
                      associationType ===
                        RelationshipMemberAssociationType.THIRD_PARTY),
                  ...(side === RelationshipMemberSide.USER_SIDE &&
                    associationType ===
                      RelationshipMemberAssociationType.THIRD_PARTY && {
                      icon: <CheckedIcon />,
                    }),
                  className: 'manage-participant-item__dropdown-suboption',
                  onClick: () => {
                    setIsDropdownOpened(false);
                    dispatch(
                      openManageParticipantsConfirmationModal({
                        note: participant.extra?.currentAccountLink?.note || '',
                        contactId: participant.contactId || '',
                        accountId: prospectAccount?.accountId || '',
                        contactType:
                          RelationshipMemberAssociationType.THIRD_PARTY,
                        assignedTo: RelationshipMemberSide.USER_SIDE,
                      })
                    );
                  },
                },
              ]
            : []),
          {
            label: 'Unassigned',
            disabled: !side || side === RelationshipMemberSide.UNASSIGNED,
            ...((!side || side === RelationshipMemberSide.UNASSIGNED) && {
              icon: <CheckedIcon />,
            }),
            className: 'manage-participant-item__dropdown-suboption',
            onClick: () => {
              setIsDropdownOpened(false);
              dispatch(
                openManageParticipantsConfirmationModal({
                  note: participant.extra?.currentAccountLink?.note || '',
                  contactId: participant.contactId || '',
                  accountId: prospectAccount?.accountId || '',
                  contactType: RelationshipMemberAssociationType.TEAM_MEMBER,
                  assignedTo: RelationshipMemberSide.UNASSIGNED,
                })
              );
            },
          },
        ],
      },
      ...(prospectAccount
        ? [
            {
              label: 'Edit Member',
              icon: <ArrowIcon />,
              className: 'manage-participant-item__dropdown-option',
              onClick: () => {
                setIsDropdownOpened(false);
                dispatch(
                  openAddMemberModal({
                    accountId: prospectAccount.accountId,
                    accountName: prospectAccount.name,
                    isEditFlow: true,
                    existingContact: participant,
                    isLinkingDisabled: true,
                    initialValues: {
                      email: email || '',
                      firstName: participant.metadata?.firstName || '',
                      lastName: participant.metadata?.lastName || '',
                      title: participant.metadata?.title || '',
                      phone:
                        participant.metadata?.phoneNumbers?.[0]?.number || '',
                      countryCode:
                        participant.metadata?.phoneNumbers?.[0]?.countryCode ||
                        '',
                      assignedTo: side || '',
                      contactType:
                        participant.extra?.currentAccountLink
                          ?.associationType || '',
                      avatar: participant.metadata.avatarSrc || '',
                      note: participant.extra?.currentAccountLink?.note || '',
                    },
                  })
                );
              },
            },
          ]
        : []),
    ];
  }, [
    associationType,
    dispatch,
    email,
    participant,
    prospectAccount,
    side,
    userOrganization,
  ]);

  const attrs = {
    container: {
      className: `manage-participant-item${
        isUnassigned ? ' manage-participant-item--unassigned' : ''
      }`,
    },
    content: {
      className: 'manage-participant-item__content',
    },
    contentOptions: {
      className:
        'manage-participant-item__content manage-participant-item__content--options',
    },
    contentUnassigned: {
      className:
        'manage-participant-item__content manage-participant-item__content--unassigned',
    },
    avatar: {
      side,
      src: participant.metadata?.avatarSrc || '',
      name: participant.metadata?.name || '',
      size: AvatarSize.S,
    },
    hostIcon: {
      className: 'manage-participant-item__host-icon',
    },
    hostLabel: {
      className: 'manage-participant-item__host-label',
    },
    email: {
      className: `manage-participant-item__email${
        email ? '' : ' manage-participant-item__email--not-detected'
      }`,
    },
    warningIcon: {
      className: 'manage-participant-item__warning-icon',
    },
    disclaimer: {
      className: 'manage-participant-item__disclaimer',
    },
    dropdown: {
      isOpened: isDropdownOpened,
      closeOnScroll: true,
      onClose: handleCloseDropdown,
      options: dropdownOptions,
      contentClassName: 'manage-participant-item__dropdown-content',
    },
    dropdownButton: {
      className: `manage-participant-item__dropdown-button${
        isDropdownOpened
          ? ' manage-participant-item__dropdown-button--active'
          : ''
      }`,
      onClick: () => {
        setIsDropdownOpened(!isDropdownOpened);
      },
    },
  };

  const name = getParticipantName(participant);

  return (
    <div {...attrs.container}>
      {isUnassigned ? (
        <div {...attrs.contentUnassigned}>
          <div {...attrs.content}>
            <Avatar {...attrs.avatar} />
            <span>{name}</span>
            {isHost ? (
              <>
                <HostIcon {...attrs.hostIcon} />
                <span {...attrs.hostLabel}>host</span>
              </>
            ) : null}
          </div>
          <div {...attrs.contentOptions}>
            <span {...attrs.email}>
              {participant.email || 'no email detected'}
            </span>
          </div>
        </div>
      ) : (
        <div {...attrs.content}>
          <Avatar {...attrs.avatar} />
          <span>{name}</span>
          {isHost ? (
            <>
              <HostIcon {...attrs.hostIcon} />
              <span {...attrs.hostLabel}>host</span>
            </>
          ) : null}
        </div>
      )}
      <div {...attrs.contentOptions}>
        {isUnassigned ? (
          <>
            <WarningIcon {...attrs.warningIcon} />
            <span {...attrs.disclaimer}>Unassigned Participant</span>
          </>
        ) : (
          <span {...attrs.email}>
            {participant.email || 'no email detected'}
          </span>
        )}
        <Dropdown {...attrs.dropdown}>
          <button {...attrs.dropdownButton}>
            <OptionsIcon />
          </button>
        </Dropdown>
      </div>
    </div>
  );
};

export default ParticipantItem;
