import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AddAccountFormValues,
  AddMemberFormValues,
  AddNoteFormValues,
  CreateAccountErrorType,
  RelationshipFlowsState,
} from './relationshipFlows.types';
import {
  createAccount,
  createContact,
  updateContact,
  linkContactToAccount,
  getExistingContactSuggestions,
  getExistingAccountSuggestions,
  updateAccount,
  createAccountAddBotFlow,
  addBot,
  getAccounts,
  assignEventToAccount,
} from './relationshipFlows.thunks';
import { Contact } from '../../types/contact.types';
import { Account } from '../../types/account.types';

const initialState: RelationshipFlowsState = {
  isLoading: {
    emailSuggestions: false,
    accountSuggestions: false,
    addAccount: false,
    createContact: false,
    linkContactToAccount: false,
    assignEventToAccount: false,
  },
  isError: {
    emailSuggestions: false,
    accountSuggestions: false,
    addAccount: false,
    createContact: false,
    linkContactToAccount: false,
    assignEventToAccount: false,
  },
  addMember: {
    isModalOpened: false,
    isEditFlow: false,
    isEditingExistingContact: false,
    existingContact: null,
    accountId: null,
    accountName: null,
    existingContactSuggestions: [],
    isLinkingDisabled: false,
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      title: '',
      countryCode: '',
      phone: '',
      assignedTo: '',
      contactType: '',
      avatar: '',
      note: '',
    },
  },
  addAccount: {
    isModalOpened: false,
    withRedirect: false,
    accountId: null,
    initialValues: {
      logo: '',
      name: '',
      website: '',
    },
  },
  assignAccount: {
    isModalOpened: false,
    isReassignFlow: false,
    eventId: null,
    selectedAccount: null,
    existingAccountSuggestions: [],
  },
  unassignAccount: {
    isModalOpened: false,
    eventId: null,
  },
  addNote: {
    isModalOpened: false,
    noteId: null,
    accountId: null,
    initialValues: {
      note: '',
    },
  },
  addBot: {
    isModalOpened: false,
    isLoading: false,
    isError: false,
    meetingUrlFieldError: false,
    isSuccess: false,
    selectedAccount: null,
    accounts: [],
    addAccount: {
      isLoading: false,
      isError: false,
      isSuccess: false,
      errorType: null,
    },
  },
  manageParticipants: {
    isModalOpened: false,
    isConfirmationModalOpened: false,
    isErrorModalOpened: false,
    hostContactId: null,
    participants: [],
    account: null,
    linkContactToAccountData: null,
  },
};

const relatioshipFlowsSlice = createSlice({
  name: 'relationshipFlows',
  initialState,
  reducers: {
    openAddMemberModal: (
      state,
      action: PayloadAction<{
        accountId: string;
        accountName: string;
        isEditFlow?: boolean;
        existingContact?: Contact;
        initialValues?: Partial<AddMemberFormValues>;
        isLinkingDisabled?: boolean;
      }>
    ) => {
      state.addMember.isModalOpened = true;
      state.addMember.accountId = action.payload.accountId;
      state.addMember.accountName = action.payload.accountName;
      state.addMember.isEditFlow = action?.payload?.isEditFlow || false;
      state.addMember.isLinkingDisabled =
        action?.payload?.isLinkingDisabled || false;
      state.addMember.existingContact =
        action?.payload?.existingContact || null;
      state.addMember.initialValues = action?.payload?.initialValues
        ? {
            ...initialState.addMember.initialValues,
            ...action.payload.initialValues,
          }
        : initialState.addMember.initialValues;
    },
    updateIsEditingExistingContact: (state, action: PayloadAction<boolean>) => {
      state.addMember.isEditingExistingContact = action.payload;
    },
    updateExistingContact: (state, action: PayloadAction<Contact | null>) => {
      state.addMember.existingContact = action.payload;
    },
    updateAddMemberFormValues: (
      state,
      action: PayloadAction<Partial<AddMemberFormValues>>
    ) => {
      state.addMember.initialValues = {
        ...initialState.addMember.initialValues,
        ...action.payload,
      };
    },
    closeAddMemberModal: (state) => {
      state.addMember.isModalOpened = false;
    },
    resetAddMemberFlow: (state) => {
      state.addMember = {
        ...initialState.addMember,
        initialValues: {
          ...initialState.addMember.initialValues,
        },
      };
    },
    openAddAccountModal: (
      state,
      action: PayloadAction<{
        accountId?: string;
        withRedirect?: boolean;
        initialValues?: Partial<AddAccountFormValues>;
      }>
    ) => {
      state.addAccount.isModalOpened = true;
      state.addAccount.accountId = action.payload.accountId || null;
      state.addAccount.withRedirect = action?.payload?.withRedirect || false;
      state.addAccount.initialValues = action?.payload?.initialValues
        ? {
            ...initialState.addAccount.initialValues,
            ...action.payload.initialValues,
          }
        : initialState.addAccount.initialValues;
    },
    closeAddAccountModal: (state) => {
      state.addAccount.isModalOpened = false;
    },
    resetAddAccountFlow: (state) => {
      state.addAccount = {
        ...initialState.addAccount,
        initialValues: {
          ...initialState.addAccount.initialValues,
        },
      };
    },
    openAssignAccountModal: (
      state,
      action: PayloadAction<{ eventId: string; isReassignFlow?: boolean }>
    ) => {
      state.assignAccount.isModalOpened = true;
      state.assignAccount.eventId = action.payload.eventId;
      state.assignAccount.isReassignFlow =
        action.payload.isReassignFlow || false;
    },
    closeAssignAccountModal: (state) => {
      state.assignAccount.isModalOpened = false;
    },
    updateSelectedAccount: (state, action: PayloadAction<Account | null>) => {
      state.assignAccount.selectedAccount = action.payload;
      state.assignAccount.existingAccountSuggestions = action.payload
        ? [action.payload]
        : state.assignAccount.existingAccountSuggestions;
    },
    resetAssignAccountFlow: (state) => {
      state.assignAccount = initialState.assignAccount;
    },
    openUnassignAccountModal: (state, action: PayloadAction<string>) => {
      state.unassignAccount.isModalOpened = true;
      state.unassignAccount.eventId = action.payload;
    },
    closeUnassignAccountModal: (state) => {
      state.unassignAccount.isModalOpened = false;
    },
    resetUnassignAccountFlow: (state) => {
      state.unassignAccount = initialState.unassignAccount;
    },
    openAddNoteModal: (
      state,
      action: PayloadAction<{
        accountId: string;
        noteId?: string;
        intialValues?: AddNoteFormValues;
      }>
    ) => {
      state.addNote.isModalOpened = true;
      state.addNote.accountId = action.payload.accountId;
      state.addNote.noteId = action.payload.noteId
        ? action.payload.noteId || null
        : state.addNote.noteId;
      state.addNote.initialValues = {
        ...state.addNote.initialValues,
        ...action.payload.intialValues,
      };
    },
    closeAddNoteModal: (state) => {
      state.addNote.isModalOpened = false;
    },
    resetAddNoteFlow: (state) => {
      state.addNote.noteId = null;
      state.addNote.accountId = null;
      state.addNote.initialValues = {
        ...initialState.addNote.initialValues,
      };
    },
    openAddBotModal: (state) => {
      state.addBot.isModalOpened = true;
    },
    closeAddBotModal: (state) => {
      state.addBot = initialState.addBot;
    },
    selectAccountAddBotFlow: (state, action: PayloadAction<Account | null>) => {
      state.addBot.selectedAccount = action.payload;
    },
    resetAddAccountStateABF: (state) => {
      state.addBot.addAccount = initialState.addBot.addAccount;
    },
    resetMeetingUrlFieldError: (state) => {
      state.addBot.meetingUrlFieldError = false;
    },
    openManageParticipantsModal: (
      state,
      action: PayloadAction<{
        participants: Contact[];
        account: Account | null;
        hostContactId: string | null;
      }>
    ) => {
      state.manageParticipants.isModalOpened = true;
      state.manageParticipants.participants = action.payload.participants;
      state.manageParticipants.account = action.payload.account;
      state.manageParticipants.hostContactId = action.payload.hostContactId;
    },
    closeManageParticipantsModal: (state) => {
      state.manageParticipants.isModalOpened = false;
    },
    openManageParticipantsConfirmationModal: (
      state,
      action: PayloadAction<{
        note: string;
        contactType: string;
        assignedTo: string;
        accountId: string;
        contactId: string;
      }>
    ) => {
      state.manageParticipants.isConfirmationModalOpened = true;
      state.manageParticipants.linkContactToAccountData = action.payload;
    },
    closeManageParticipantsConfirmationModal: (state) => {
      state.manageParticipants.isConfirmationModalOpened = false;
    },
    openManageParticipantsErrorModal: (state) => {
      state.manageParticipants.isErrorModalOpened = true;
    },
    closeManageParticipantsErrorModal: (state) => {
      state.manageParticipants.isErrorModalOpened = false;
    },
    resetManageParticipantsFlow: (state) => {
      state.manageParticipants = initialState.manageParticipants;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createAccount.pending, (state) => {
      state.isLoading.addAccount = true;
      state.isError.addAccount = false;
    });
    builder.addCase(createAccount.fulfilled, (state) => {
      state.isLoading.addAccount = false;
    });
    builder.addCase(createAccount.rejected, (state, action) => {
      state.isLoading.addAccount = false;
      state.isError.addAccount = !action.meta.aborted;
    });
    builder.addCase(updateAccount.pending, (state) => {
      state.isLoading.addAccount = true;
      state.isError.addAccount = false;
    });
    builder.addCase(updateAccount.fulfilled, (state) => {
      state.isLoading.addAccount = false;
    });
    builder.addCase(updateAccount.rejected, (state, action) => {
      state.isLoading.addAccount = false;
      state.isError.addAccount = !action.meta.aborted;
    });
    builder.addCase(createContact.pending, (state) => {
      state.isLoading.createContact = true;
      state.isError.createContact = false;
    });
    builder.addCase(createContact.fulfilled, (state) => {
      state.isLoading.createContact = false;
    });
    builder.addCase(createContact.rejected, (state, action) => {
      state.isLoading.createContact = false;
      state.isError.createContact = !action.meta.aborted;
    });
    builder.addCase(updateContact.pending, (state) => {
      state.isLoading.createContact = true;
      state.isError.createContact = false;
    });
    builder.addCase(updateContact.fulfilled, (state, action) => {
      const { arg } = action.meta;
      const participantToUpdate = state.manageParticipants.participants.find(
        (participant) => participant.contactId === action.payload.contactId
      );
      const side = participantToUpdate?.extra?.currentAccountLink?.contactSide;

      state.isLoading.createContact = false;
      state.manageParticipants.participants = state.manageParticipants.participants.map(
        (contact) =>
          contact.contactId === action.payload.contactId
            ? {
                ...action.payload,
                extra: {
                  ...contact.extra,
                  ...action.payload.extra,
                },
              }
            : contact
      );

      if (
        state.manageParticipants.account &&
        participantToUpdate &&
        side &&
        arg.assignedTo !== side
      ) {
        state.manageParticipants.isConfirmationModalOpened = true;
        state.manageParticipants.linkContactToAccountData = {
          note: arg.note,
          contactType: arg.contactType,
          assignedTo: arg.assignedTo,
          accountId: state.manageParticipants.account.accountId,
          contactId: participantToUpdate.contactId,
        };
      }
    });
    builder.addCase(updateContact.rejected, (state, action) => {
      state.isLoading.createContact = false;
      state.isError.createContact = !action.meta.aborted;
    });
    builder.addCase(linkContactToAccount.pending, (state) => {
      state.isLoading.linkContactToAccount = true;
      state.isError.linkContactToAccount = false;
    });
    builder.addCase(linkContactToAccount.fulfilled, (state) => {
      state.isLoading.linkContactToAccount = false;
      state.manageParticipants.isModalOpened = false;
      state.manageParticipants.isConfirmationModalOpened = false;
      state.manageParticipants.isErrorModalOpened = false;
    });
    builder.addCase(linkContactToAccount.rejected, (state, action) => {
      const isManageParticipantsModalOpened =
        state.manageParticipants.isModalOpened;
      state.isLoading.linkContactToAccount = false;
      state.isError.linkContactToAccount = !action.meta.aborted;

      if (isManageParticipantsModalOpened) {
        state.manageParticipants.isErrorModalOpened = !action.meta.aborted;
      }
    });
    builder.addCase(getExistingContactSuggestions.pending, (state) => {
      state.isLoading.emailSuggestions = true;
      state.isError.emailSuggestions = false;
    });
    builder.addCase(
      getExistingContactSuggestions.fulfilled,
      (state, action) => {
        state.addMember.existingContactSuggestions = action.payload;
        state.isLoading.emailSuggestions = false;
      }
    );
    builder.addCase(getExistingContactSuggestions.rejected, (state, action) => {
      state.isLoading.emailSuggestions = false;
      state.isError.emailSuggestions = !action.meta.aborted;
    });
    builder.addCase(getExistingAccountSuggestions.pending, (state) => {
      state.isLoading.accountSuggestions = true;
      state.isError.accountSuggestions = false;
    });
    builder.addCase(
      getExistingAccountSuggestions.fulfilled,
      (state, action) => {
        state.assignAccount.existingAccountSuggestions = action.payload;
        state.isLoading.accountSuggestions = false;
      }
    );
    builder.addCase(getExistingAccountSuggestions.rejected, (state, action) => {
      state.isLoading.accountSuggestions = false;
      state.isError.accountSuggestions = !action.meta.aborted;
    });

    builder.addCase(getAccounts.fulfilled, (state, action) => {
      state.addBot.accounts = action.payload;
      state.assignAccount.existingAccountSuggestions = action.payload;
    });

    builder.addCase(createAccountAddBotFlow.pending, (state) => {
      state.addBot.addAccount.isLoading = true;
      state.addBot.addAccount.isError = false;
      state.addBot.addAccount.isSuccess = false;
    });
    builder.addCase(createAccountAddBotFlow.fulfilled, (state, action) => {
      state.addBot.accounts = action.payload
        ? [...state.addBot.accounts, action.payload]
        : state.addBot.accounts;
      state.addBot.selectedAccount =
        action.payload || state.addBot.selectedAccount;
      state.addBot.addAccount.isLoading = false;
      state.addBot.addAccount.isSuccess = true;
    });
    builder.addCase(createAccountAddBotFlow.rejected, (state, action) => {
      state.addBot.addAccount.isLoading = false;
      state.addBot.addAccount.isError = !action.meta.aborted;
      state.addBot.addAccount.errorType =
        (action?.payload as any)?.response?.status === 409
          ? ('name exists' as CreateAccountErrorType)
          : null;
    });

    builder.addCase(addBot.pending, (state) => {
      state.addBot.isLoading = true;
      state.addBot.isError = false;
      state.addBot.isSuccess = false;
    });
    builder.addCase(addBot.fulfilled, (state) => {
      state.addBot.isLoading = false;
      state.addBot.isSuccess = true;
      state.addBot.selectedAccount = null;
    });
    builder.addCase(addBot.rejected, (state, action) => {
      state.addBot.isLoading = false;
      state.addBot.isSuccess = false;
      state.addBot.isError = !action.meta.aborted;
      state.addBot.meetingUrlFieldError =
        (action?.payload as any)?.response?.status === 422;
    });
    builder.addCase(assignEventToAccount.pending, (state) => {
      state.isLoading.assignEventToAccount = true;
      state.isError.assignEventToAccount = false;
    });
    builder.addCase(assignEventToAccount.fulfilled, (state) => {
      state.isLoading.assignEventToAccount = false;
    });
    builder.addCase(assignEventToAccount.rejected, (state, action) => {
      state.isLoading.assignEventToAccount = false;
      state.isError.assignEventToAccount = !action.meta.aborted;
    });
  },
});

export const {
  openAddMemberModal,
  updateIsEditingExistingContact,
  updateExistingContact,
  updateAddMemberFormValues,
  closeAddMemberModal,
  resetAddMemberFlow,
  openAddAccountModal,
  closeAddAccountModal,
  resetAddAccountFlow,
  openAssignAccountModal,
  closeAssignAccountModal,
  updateSelectedAccount,
  resetAssignAccountFlow,
  openUnassignAccountModal,
  closeUnassignAccountModal,
  resetUnassignAccountFlow,
  openAddNoteModal,
  closeAddNoteModal,
  resetAddNoteFlow,
  openAddBotModal,
  closeAddBotModal,
  selectAccountAddBotFlow,
  resetAddAccountStateABF,
  resetMeetingUrlFieldError,
  openManageParticipantsModal,
  closeManageParticipantsModal,
  openManageParticipantsConfirmationModal,
  closeManageParticipantsConfirmationModal,
  openManageParticipantsErrorModal,
  closeManageParticipantsErrorModal,
  resetManageParticipantsFlow,
} = relatioshipFlowsSlice.actions;

export default relatioshipFlowsSlice.reducer;
