import React, { useState } from 'react';
import { useIsTextTruncated } from '../../../../../../hooks';
import { ChevronDownIcon } from '../../../../icons/ChevronDownIcon';
import { Tooltip } from '../../../Tooltip/Tooltip';

export interface DropdownOptionProps {
  label?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  className?: string;
  suboptions?: DropdownOptionProps[];
  isExpanded?: boolean;
  onClick?: () => void;
}

export const DropdownOption: React.FC<DropdownOptionProps> = ({
  label,
  disabled = false,
  icon: iconProp,
  className,
  suboptions,
  isExpanded: isExpandedProp = false,
  onClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedProp);
  const { ref, isTextTruncated } = useIsTextTruncated();

  const attrs = {
    dropdownOption: {
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation();

        if (suboptions?.length && !disabled) {
          setIsExpanded(!isExpanded);
        }

        onClick?.();
      },
      className: `sound-wave-dropdown__option${
        isExpanded ? ' sound-wave-dropdown__option--expanded' : ''
      }${className ? ` ${className}` : ''}`,
      ...(!suboptions && { type: 'button' as const, disabled }),
    },
    dropdownOptionLabel: {
      className: 'sound-wave-dropdown__option-label',
    },
    dropdownOptionLabelText: {
      ref,
      className: 'sound-wave-dropdown__option-label-text',
    },
    dropdownOptionLabelTooltip: {
      className: 'sound-wave-dropdown__option-label-tooltip',
      contentClassName: 'sound-wave-dropdown__option-label-tooltip-content',
      text: label,
      simplified: true,
    },
    dropdownIcon: {
      className: 'sound-wave-dropdown__dropdown-icon',
    },
    suboptionsWrapper: {
      className: 'sound-wave-dropdown__suboptions-wrapper',
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation();
      },
      ...(isExpanded && {
        style: { maxHeight: `${((suboptions?.length || 0) + 1) * 36}px` },
      }),
    },
  };

  const DropdownOptionComponent = suboptions ? 'div' : 'button';
  const icon =
    suboptions && !iconProp ? (
      <ChevronDownIcon {...attrs.dropdownIcon} />
    ) : (
      iconProp
    );

  const renderSuboptions = () =>
    suboptions?.map((suboption, index) => {
      const props = {
        suboption: {
          key: index,
          ...suboption,
        },
      };

      return <DropdownOption {...props.suboption} />;
    });

  return (
    <DropdownOptionComponent {...attrs.dropdownOption}>
      {isTextTruncated ? (
        <Tooltip {...attrs.dropdownOptionLabelTooltip}>
          <div {...attrs.dropdownOptionLabel}>
            <span {...attrs.dropdownOptionLabelText}>{label}</span>
            {icon}
          </div>
        </Tooltip>
      ) : (
        <div {...attrs.dropdownOptionLabel}>
          <span {...attrs.dropdownOptionLabelText}>{label}</span>
          {icon}
        </div>
      )}
      {suboptions?.length ? (
        <div {...attrs.suboptionsWrapper}>{renderSuboptions()}</div>
      ) : null}
    </DropdownOptionComponent>
  );
};
