import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RelationshipMemberSide } from '../../types/relationshipMember.types';
import { groupBy } from '../../utils/groupBy';
import { RootState } from '../store';

export const participantsBySideSelector = createDraftSafeSelector(
  (state: RootState) => state.relatioshipFlows.manageParticipants.participants,
  (participants) =>
    groupBy(
      participants,
      (participant) =>
        participant.extra?.currentAccountLink?.contactSide ||
        RelationshipMemberSide.UNASSIGNED
    )
);

export const hostParticipantSelector = createDraftSafeSelector(
  (state: RootState) => state.relatioshipFlows.manageParticipants.participants,
  (state: RootState) => state.relatioshipFlows.manageParticipants.hostContactId,
  (participants, hostContactId) =>
    participants.find(
      (participant) => participant.contactId === hostContactId
    ) || null
);

export const participantToUpdateSelector = createDraftSafeSelector(
  (state: RootState) => state.relatioshipFlows.manageParticipants.participants,
  (state: RootState) =>
    state.relatioshipFlows.manageParticipants.linkContactToAccountData,
  (participants, linkContactToAccountData) => {
    if (!linkContactToAccountData) {
      return null;
    }

    return (
      participants.find(
        (participant) =>
          participant.contactId === linkContactToAccountData.contactId
      ) || null
    );
  }
);
