import React from 'react';
import { Tooltip } from '../../../../components/SoundWave';
import { Contact } from '../../../../types/contact.types';
import { MeetingParticipantWithContactInfo } from '../../../../types/meetingDetails.types';
import { getParticipantName } from '../../utils';

export interface ParticipantTooltipProps {
  participant: MeetingParticipantWithContactInfo | Contact;
  className?: string;
}

const ParticipantTooltip: React.FC<ParticipantTooltipProps> = ({
  participant,
  className,
  children,
}) => {
  const renderTooltipContent = () => {
    const props = {
      container: {
        className: 'meeting-participant-tooltip__content',
      },
      title: {
        className: 'meeting-participant-tooltip__title',
      },
      divider: {
        className: 'meeting-participant-tooltip__divider',
      },
    };

    const name = getParticipantName(participant, true);
    const title =
      'contact' in participant
        ? participant.contact?.metadata?.title || ''
        : participant.metadata?.title || '';
    const email =
      'contact' in participant
        ? participant.contact?.email || ''
        : participant.email || '';

    return (
      <div {...props.container}>
        <div {...props.title}>
          <span>{name}</span>
          {title ? (
            <>
              <span {...props.divider} />
              <span>{title}</span>
            </>
          ) : null}
        </div>
        {email ? <div>{email}</div> : null}
      </div>
    );
  };

  const attrs = {
    tooltip: {
      className: `meeting-participant-tooltip${
        className ? ` ${className}` : ''
      }`,
      contentClassName: 'meeting-participant-tooltip__tooltip',
      text: renderTooltipContent(),
    },
  };

  return <Tooltip {...attrs.tooltip}>{children}</Tooltip>;
};

export default ParticipantTooltip;
