import React, { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import {
  EventHeaderTitle,
  EventHeaderTitleProps,
} from './components/EventHeaderTitle/EventHeaderTitle';
import { Account } from '../../../../types/account.types';
import { LogoPlaceholderIcon } from '../../../Icons';
import { useAppDispatch } from '../../../../store/hooks';
import { openAssignAccountModal } from '../../../../store/relationshipFlows/relationshipFlows.slice';
import {
  Avatar,
  AvatarSize,
  Button,
  ButtonSize,
  ButtonVariant,
  Tooltip,
  TooltipProps,
} from '../../../SoundWave';
import { InfoIcon } from './icons/InfoIcon';

export enum DescriptionAlignment {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface EventHeaderProps {
  titleProps: EventHeaderTitleProps;
  isLoading: boolean;
  leftIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  rightIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  account?: Account | null;
  showAccount?: boolean;
  eventId?: string;
  buttonProps?: {
    text: string;
    disabled?: boolean;
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
    tooltipProps?: TooltipProps;
    onClick: () => void;
  };
}

export const EventHeader: React.FC<EventHeaderProps> = ({
  titleProps,
  isLoading,
  leftIcon,
  rightIcon,
  buttonProps,
  account,
  eventId,
  showAccount = false,
}) => {
  const dispatch = useAppDispatch();

  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const LeftIconComponent = leftIcon || null;
  const RightIconComponent = rightIcon || null;
  const ButtonIconComponent = buttonProps?.icon || null;

  const attrs = {
    container: {
      className: `event-header${
        showDisclaimer ? ' event-header--with-disclaimer' : ''
      }`,
    },
    left: {
      className: 'event-header__left',
    },
    right: {
      className: 'event-header__right',
    },
    leftIcon: {
      className: `event-header__left-icon${
        isLoading ? ' event-header__left-icon--loading' : ''
      }`,
    },
    rightIcon: {
      className: 'event-header__right-icon',
    },
    tooltip: {
      text: 'Select Account',
      width: 124,
    },
    accountButtonWrapper: {
      className: 'event-header__account-button-wrapper',
    },
    accountButton: {
      disabled: isLoading || !!account,
      type: 'button' as const,
      className: 'event-header__account-button',
      onClick: () => {
        if (!account && eventId) {
          setShowDisclaimer(false);
          dispatch(openAssignAccountModal({ eventId }));
        }
      },
    },
    disclaimer: {
      className: 'event-header__disclaimer',
    },
    disclaimerContent: {
      className: 'event-header__disclaimer-content',
    },
    disclaimerIcon: {
      className: 'event-header__disclaimer-icon',
    },
    disclaimerTitle: {
      className: 'event-header__disclaimer-title',
    },
    disclaimerAssociateButton: {
      type: 'button' as const,
      className: 'event-header__disclaimer-associate-button',
      onClick: () => {
        if (!account && eventId) {
          setShowDisclaimer(false);
          dispatch(openAssignAccountModal({ eventId }));
        }
      },
    },
    disclaimerCloseButton: {
      type: 'button' as const,
      className: 'confirmation-modal__button',
      onClick: () => {
        setShowDisclaimer(false);
      },
    },
    disclaimerOverlay: {
      className: 'event-header__disclaimer-overlay',
      onClick: () => {
        setShowDisclaimer(false);
      },
    },
    accountLogo: {
      size: AvatarSize.XS,
      src: account?.avatarSrc || '',
      name: account?.name || '',
      placeholder: LogoPlaceholderIcon,
      hasBorder: !account,
      shape: 'square' as const,
      className: 'event-header__account-logo',
    },
    separator: {
      className: 'event-header__separator',
    },
    titleSkeleton: {
      active: true,
      paragraph: false,
      loading: isLoading,
      style: {
        width: 'auto',
      },
      title: {
        className: 'sound-wave-skeleton',
        style: {
          width: 128,
          height: 16,
          margin: 0,
          borderRadius: 8,
        },
      },
    },
    button: {
      size: ButtonSize.XS,
      variant: ButtonVariant.Secondary,
      disabled: isLoading || !!buttonProps?.disabled,
      onClick: () => {
        buttonProps?.onClick();
      },
    },
    buttonTooltip: {
      width: 200,
      ...(buttonProps?.tooltipProps || {}),
    },
  };

  const renderAccountButton = () => {
    if (!account) {
      return (
        <div {...attrs.accountButtonWrapper}>
          <Tooltip {...attrs.tooltip}>
            <button {...attrs.accountButton}>
              <Avatar {...attrs.accountLogo} />
              <span>N/A</span>
            </button>
          </Tooltip>
          <div {...attrs.disclaimer}>
            <div {...attrs.disclaimerContent}>
              <InfoIcon {...attrs.disclaimerIcon} />
              <div {...attrs.disclaimerTitle}>
                This event is not associated to an Account
              </div>
              <button {...attrs.disclaimerAssociateButton}>
                Associate Now
              </button>
              <button {...attrs.disclaimerCloseButton}>Close</button>
            </div>
          </div>
          <div {...attrs.disclaimerOverlay} />
        </div>
      );
    }

    return (
      <button {...attrs.accountButton}>
        <Avatar {...attrs.accountLogo} />
        <span>{account.name}</span>
      </button>
    );
  };

  const renderButton = () => {
    if (!buttonProps) {
      return null;
    }

    if (buttonProps.tooltipProps) {
      return (
        <Tooltip {...attrs.buttonTooltip}>
          <Button {...attrs.button}>
            <span>{buttonProps.text}</span>
            {ButtonIconComponent ? <ButtonIconComponent /> : null}
          </Button>
        </Tooltip>
      );
    }

    return (
      <Button {...attrs.button}>
        <span>{buttonProps.text}</span>
        {ButtonIconComponent ? <ButtonIconComponent /> : null}
      </Button>
    );
  };

  useEffect(() => {
    if (showAccount && !isLoading && !account) {
      setShowDisclaimer(true);
    }
  }, [account, isLoading, showAccount]);

  return (
    <div {...attrs.container}>
      <div {...attrs.left}>
        {!isLoading && showAccount ? (
          <>
            {renderAccountButton()}
            <div {...attrs.separator}>|</div>
          </>
        ) : null}
        {LeftIconComponent ? <LeftIconComponent {...attrs.leftIcon} /> : null}
        {isLoading ? (
          <Skeleton {...attrs.titleSkeleton} />
        ) : (
          <EventHeaderTitle {...titleProps} />
        )}
        {RightIconComponent ? (
          <RightIconComponent {...attrs.rightIcon} />
        ) : null}
      </div>
      <div {...attrs.right}>{renderButton()}</div>
    </div>
  );
};
