import React from 'react';

const WarningIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.12804 2.15423C4.45664 1.45008 5.16335 1 5.9404 1H6.05962C6.83667 1 7.54338 1.45008 7.87199 2.15423L11.004 8.86567C11.4681 9.86015 10.7421 11 9.64472 11H2.35531C1.25788 11 0.531944 9.86014 0.996031 8.86567L4.12804 2.15423ZM6.00001 3C6.27615 3 6.50001 3.22386 6.50001 3.5V7C6.50001 7.27614 6.27615 7.5 6.00001 7.5C5.72387 7.5 5.50001 7.27614 5.50001 7V3.5C5.50001 3.22386 5.72387 3 6.00001 3ZM6.00001 9.25C6.34519 9.25 6.62501 8.97018 6.62501 8.625C6.62501 8.27982 6.34519 8 6.00001 8C5.65483 8 5.37501 8.27982 5.37501 8.625C5.37501 8.97018 5.65483 9.25 6.00001 9.25Z"
      fill="#F0384A"
    />
  </svg>
);

export default WarningIcon;
