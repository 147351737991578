import { useEffect, useRef, useState } from 'react';

export const useIsTextTruncated = <T extends HTMLElement>(
  refProp?: React.RefObject<T>
): {
  isTextTruncated: boolean;
  ref: React.MutableRefObject<T | null>;
} => {
  const [isTextTruncated, setIsTextTruncated] = useState(false);
  const ref = useRef<T | null>(refProp?.current || null);

  useEffect(() => {
    const element = ref.current;

    if (element) {
      const { scrollWidth, clientWidth } = element;
      setIsTextTruncated(scrollWidth > clientWidth);
    }
  }, []);

  return {
    isTextTruncated,
    ref,
  };
};
