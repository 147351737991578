import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getParticipantName } from '../../../../../../../../pages/MeetingDetails/utils';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../store/hooks';
import { getMeetingDetails } from '../../../../../../../../store/meetingDetails/meetingDetails.thunks';
import { participantToUpdateSelector } from '../../../../../../../../store/relationshipFlows/relationshipFlows.selectors';
import { closeManageParticipantsConfirmationModal } from '../../../../../../../../store/relationshipFlows/relationshipFlows.slice';
import { linkContactToAccount } from '../../../../../../../../store/relationshipFlows/relationshipFlows.thunks';
import { getThreadDetails } from '../../../../../../../../store/threadDetails/threadDetails.thunks';
import { Avatar, ConfirmationModal } from '../../../../../../../SoundWave';

const ManageParticipantsConfirmationModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const account = useAppSelector(
    (state) => state.relatioshipFlows.manageParticipants.account
  );

  const organization = useAppSelector((state) => state.auth.org);

  const linkContactToAccountData = useAppSelector(
    (state) =>
      state.relatioshipFlows.manageParticipants.linkContactToAccountData
  );

  const isModalOpened = useAppSelector(
    (state) =>
      state.relatioshipFlows.manageParticipants.isConfirmationModalOpened
  );

  const isLoading = useAppSelector(
    (state) => state.relatioshipFlows.isLoading.linkContactToAccount
  );

  const participantToUpdate = useAppSelector(participantToUpdateSelector);

  const requestRef = useRef<(Promise<unknown> & { abort: () => void }) | null>(
    null
  );

  const { meetingId } = useParams<{
    meetingId: string;
  }>();

  const { threadId } = useParams<{
    threadId: string;
  }>();

  const text = `Are you sure you want to reassign ${getParticipantName(
    participantToUpdate,
    true
  )}? This change will trigger a reanalysis of all events related to the relationship between ${
    account?.name || 'Prospect'
  } and ${organization?.name || 'Your Organization'}.`;

  const attrs = {
    modal: {
      isOpened: isModalOpened,
      title: 'Confirm Reassignment',
      description: text,
      footerProps: {
        isLoading,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Confirm',
        onConfirm: () => {
          if (linkContactToAccountData) {
            dispatch(linkContactToAccount(linkContactToAccountData))
              .unwrap()
              .then(() => {
                if (threadId) {
                  requestRef.current = dispatch(getThreadDetails(threadId));
                } else if (meetingId) {
                  requestRef.current = dispatch(getMeetingDetails(meetingId));
                }
              });
          }
        },
      },
      onClose: () => {
        dispatch(closeManageParticipantsConfirmationModal());
      },
    },

    avatar: {
      side: participantToUpdate?.extra?.currentAccountLink?.contactSide,
      src: participantToUpdate?.metadata?.avatarSrc || '',
      name: participantToUpdate?.metadata?.name || '',
    },
  };

  useEffect(() => {
    const currentRequest = requestRef.current;

    return () => {
      currentRequest?.abort();
    };
  }, [meetingId, threadId]);

  return (
    <ConfirmationModal {...attrs.modal}>
      <Avatar {...attrs.avatar} />
    </ConfirmationModal>
  );
};

export default ManageParticipantsConfirmationModal;
