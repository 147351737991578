import React from 'react';

const HostIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      d="M5.16078 2.16669C5.45772 1.40017 6.5423 1.40016 6.83924 2.16669L7.5555 4.01567C7.5838 4.08871 7.65212 4.13851 7.73032 4.14309L9.71244 4.25906C10.529 4.30683 10.8636 5.33093 10.2328 5.85161L8.69033 7.12481C8.63036 7.17431 8.60452 7.2541 8.62408 7.32936L9.12681 9.2635C9.33321 10.0576 8.45629 10.6911 7.76722 10.2458L6.10857 9.17374C6.04249 9.13104 5.95752 9.13104 5.89144 9.17374L4.23279 10.2458C3.54372 10.6911 2.6668 10.0576 2.8732 9.2635L3.37593 7.32936C3.3955 7.2541 3.36966 7.17431 3.30968 7.12481L1.76722 5.85161C1.13642 5.33093 1.47104 4.30683 2.28757 4.25906L4.2697 4.14309C4.34789 4.13851 4.41622 4.08871 4.44451 4.01567L5.16078 2.16669Z"
      fill="#EE72A3"
    />
  </svg>
);

export default HostIcon;
