import React from 'react';

const TooltipIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3334 10.0007C18.3334 5.39828 14.6024 1.66732 10 1.66732C5.39765 1.66732 1.66669 5.39828 1.66669 10.0007C1.66669 14.603 5.39765 18.334 10 18.334C14.6024 18.334 18.3334 14.603 18.3334 10.0007ZM10 15.0007C10.4603 15.0007 10.8334 14.6276 10.8334 14.1673V8.33398C10.8334 7.87375 10.4603 7.50065 10 7.50065C9.53978 7.50065 9.16669 7.87375 9.16669 8.33398V14.1673C9.16669 14.6276 9.53978 15.0007 10 15.0007ZM10 4.58398C10.5753 4.58398 11.0417 5.05035 11.0417 5.62565C11.0417 6.20095 10.5753 6.66732 10 6.66732C9.42472 6.66732 8.95835 6.20095 8.95835 5.62565C8.95835 5.05035 9.42472 4.58398 10 4.58398Z"
      fill="#95A1B6"
    />
  </svg>
);

export default TooltipIcon;
