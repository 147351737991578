export enum BottomNotificationType {
  Success = 'success',
}

export interface BottomNotificationState {
  isOpened: boolean;
  text: string | null;
  position: number | null;
  type: BottomNotificationType | null;
}
